import * as React from "react";
import { Grid } from "@mui/material";
import Input from "../../utilities/input";
import Label from "../../utilities/label";
import Button from "../../utilities/button";
import ModalForm from "../../utilities/modal";
import MessagePopUp from "./messagePopUp";
import { axiosInstance } from "../../services/axiosInstance";
import { endpoints } from "../../services/endpoints";
import { companyDetailsContext, othersFormContext } from "../../context";
import "./companyDetails.css";
import CircularProgress from "@mui/material/CircularProgress";
import { validateEmail, validatePhoneNumber } from "../../preDefinedFunctions/inputFieldValidations";
import {homePageContext} from "../../context";

export default function OthersForm({ handleClose }) {
  const [openMessagePopUp, setOpenMessagePopUp] = React.useState(false);
  // let othersForm = React.useContext(othersFormContext);
  const companyDetails = React.useContext(companyDetailsContext);
  const homePageDetails = React.useContext(homePageContext);

  const othersDetails = React.useContext(othersFormContext);
  const [othersFormDetails, setOthersFormDetails] = React.useState({
    firstName: othersDetails.firstName || "",
    lastName: othersDetails.lastName || "",
    organizationName: othersDetails.organizationName || "",
    email: othersDetails.email || "",
    phoneNumber: othersDetails.phoneNumber || "",
    industry: othersDetails.industry || "",
    useCase: othersDetails.useCase || "",
    description: othersDetails.description || "",
    countryCode: othersDetails.countryCode || "+1",
  });
  const handleOpenMessagePopUp = () => {
    setOpenMessagePopUp(true);
  };
  const [loading, setLoading] = React.useState(false);
  const handleCloseMessagePopUp = () => {
    setOpenMessagePopUp(false);
  };

  const [apiData, setApiData] = React.useState({});
  const [industryList, setIndustryList] = React.useState([]);
  const [countryCodes, setCountryCodes] = React.useState([]);
  const [useCaseList, setUseCaseList] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState({});

  React.useEffect(() => {
    getIndustryList();
    //getCountryCodes();
  }, []);

  async function getIndustryList() {
    try {
      // const headers = {
      //   "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
      // };
      const response = await axiosInstance.get(endpoints.getIndustryList
      );
      if (response.status === 200) {
        const data = JSON.parse(response.data);
        setApiData(data);
        const keysArray = Object.keys(data);
        setIndustryList(keysArray);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getCountryCodes() {
    try {
      const response = await axiosInstance.get(
        endpoints.claimPhoneNumber.getCountryList
      );
      if (response.status === 200) {
        const data = response.data;
        setCountryCodes(data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleValidateEmail = (id, value) => {
    if (!validateEmail(value)) {
      setErrorMessage({ [id]: "Enter valid email address" });
      setOthersFormDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value.toLowerCase(),
      }));
    } else {
      setErrorMessage({});
      setOthersFormDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value.toLowerCase(),
      }));
    }
    return;
  };

  const handleOnEMailChange = (e) => {
    const { id, value } = e.target;
    if (id === "email") {
      handleValidateEmail(id, value);
    }
  };
  const handleOnPhoneChange=(e) =>{
    const{id,value}=e.target;
    if(id==="phoneNumber"){
      handleValidatePhoneNumber(id,value);
    }
  }

  const handleOnChange = (e) => {
    const { id, value, name } = e.target;
    if (id === "phoneNumber") {
      validatePhoneNumber(id, value);
    }
    setOthersFormDetails((prevDetails) => ({
      ...prevDetails,
      [name ? name : id]: value,
    }));
    if (name === "industry") {
      handleUseCases(value);
    }
  };

  function removeDuplicates(arr) {
    let unique = [];
    arr.forEach((element) => {
      if (!unique.includes(element.callingCodes)) {
        unique.push(element.callingCodes);
      }
    });
    return unique;
  }
  const OthersFormDetailsInputFields = React.useMemo(
    () => [
      {
        label: "First name",
        valueKey: "firstName",
        type: "text",
        required: true,
        id: "first-name",
        disabled: false,
        placeholder: "e.g Stephen",
        value: othersFormDetails.firstName,
        errorMessage: errorMessage["firstName"],
        handleOnChange: handleOnChange,
      },
      {
        label: "Last name",
        valueKey: "lastName",
        type: "text",
        required: true,
        id: "last-name",
        disabled: false,
        placeholder: "e.g Mathew",
        value: othersFormDetails.lastName,
        errorMessage: errorMessage["lastName"],
        handleOnChange: handleOnChange,
      },
      {
        label: "Email",
        valueKey: "email",
        type: "text",
        required: true,
        id: "email",
        disabled: false,
        placeholder: "e.g stephen@example.com",
        value: othersFormDetails.email,
        errorMessage: errorMessage["email"],
        handleOnChange: handleOnEMailChange,
      },
      {
        label: "Phone number",
        valueKey: "phoneNumber",
        type: "phoneNumber",
        required: true,
        id: "phone-number",
        disabled: false,
        placeholder: "e.g 7345678954",
        value: othersFormDetails.phoneNumber,
        countryCode: othersFormDetails.countryCode,
        onKeyDown: `countryCodes.callingCodes`,
        errorMessage: errorMessage["phoneNumber"],
        options: removeDuplicates(countryCodes),
        handleOnChange: handleOnPhoneChange,
      },
      {
        label: "Organization name",
        valueKey: "organizationName",
        type: "text",
        required: true,
        id: "organization-name",
        disabled: false,
        placeholder: "e.g ConnectGen",
        value: othersFormDetails.organizationName,
        errorMessage: errorMessage["organizationName"],
        handleOnChange: handleOnChange,
      },
      {
        label: "Industry",
        valueKey: "industry",
        type: "select",
        required: true,
        id: "industry",
        disabled: false,
        placeholder: "Select industry",
        value: othersFormDetails.industry,
        options: industryList,
        errorMessage: errorMessage["industry"],
        handleOnChange: handleOnChange,
      },
      {
        label: "Usecase",
        valueKey: "useCase",
        type: "select",
        required: true,
        id: "useCase",
        disabled: false,
        placeholder: "Select usecase",
        value: othersFormDetails.useCase,
        options: useCaseList,
        errorMessage: errorMessage["useCase"],
        handleOnChange: handleOnChange,
      },
      {
        label: "Description",
        valueKey: "description",
        type: "text",
        required: true,
        id: "description",
        disabled: false,
        placeholder: "Enter message",
        value: othersFormDetails.description,
        errorMessage: errorMessage["description"],
        handleOnChange: handleOnChange,
      },
    ],
    [othersFormDetails, errorMessage, countryCodes]
  );

  const handleUseCases = (key) => {
    const useCasesData = apiData[key];
    setUseCaseList(useCasesData);
  };

  const handleOnCountryCode = (e) => {
    const { value } = e.target;
    setOthersFormDetails((prevDetails) => ({
      ...prevDetails,
      countryCode: value,
    }));
  };

  const handleValidatePhoneNumber = (id, value) => {
    if (!othersDetails.countryCode) {
      setErrorMessage({ [id]: "Please select country code" });
    } else {
      setErrorMessage({});
    }
    if (!validatePhoneNumber(value)) {
      setErrorMessage({
        [id]: "Please enter valid phone number - only numbers",
      });
      setOthersFormDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
      return;
    } else if (value.length < 10) {
      setErrorMessage({
        [id]: "Please Enter valid phone number with 10 digits",
      });
      setOthersFormDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
      return;
    } else {
      setErrorMessage({});
      setOthersFormDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    }
  };

  const handleFormFieldsValidation = (formData) => {
    const errors = {};
    const {
      firstName,
      lastName,
      organizationName,
      email,
      phoneNumber,
      industry,
      useCase,
      description,
    } = formData;

    if (!firstName) {
      errors["firstName"] = "Please Enter First Name";
    }

    if (!lastName) {
      errors["lastName"] = "Please Enter Last Name";
    }

    if (!organizationName) {
      errors["organizationName"] = "Please Enter Organization Name";
    }

    if (!email) {
      errors["email"] = "Please Enter Email";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors["email"] = "Please Enter a Valid Email";
    }

   
if (!phoneNumber) {
  errors["phoneNumber"] = "Please Enter Phone Number";
} else if (!validatePhoneNumber(phoneNumber)) {
  errors["phoneNumber"] = "Please provide Valid Phone Number";
}

    if (!industry) {
      errors["industry"] = "Please Select Industry";
    }

    if (!useCase) {
      errors["useCase"] = "Please Select Use Case";
    }

    if (!description) {
      errors["description"] = "Please Enter Description";
    }

    setErrorMessage(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    const isValid = handleFormFieldsValidation(othersFormDetails);
    if (isValid) {
      setLoading(true);
      const body = {
        firstName: othersFormDetails.firstName,
        lastName: othersFormDetails.lastName,
        organizationName: othersFormDetails.organizationName,
        email: othersFormDetails.email,
        phoneNumber:
          othersFormDetails.countryCode + othersFormDetails.phoneNumber,
        industry: othersFormDetails.industry,
        useCase: othersFormDetails.useCase,
        description: othersFormDetails.description,
        uniqueId: homePageDetails.uId,
        companyName:othersFormDetails.organizationName,
      };
      const headers = {
        "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
      };
      axiosInstance
        .post(endpoints.othersDetails.detailsSubmit, body, {
          headers: headers,
        })
        .then((response) => {
          if (response) {
            setLoading(false);
            handleOpenMessagePopUp();
            setTimeout(() => {
              handleClose();
              handleCloseMessagePopUp();
            }, 5000);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div className="others-form-container">
      <Grid container spacing={1} className="others-container-section">
        {OthersFormDetailsInputFields.map((item, index) => (
          <Grid
            item
            md={6}
            xs={12}
            spacing={1}
            className="input-div-class"
            key={index}
          >
            <Label title={item.label} />
            <Input
              type={item.type}
              disabled={item.disabled}
              required={item.required}
              id={item.valueKey}
              label={item.label}
              handleOnChange={item.handleOnChange}
              handleOnCountryCode={handleOnCountryCode}
              value={item.value}
              placeholder={item.placeholder}
              options={item.options}
              countryCode={item.countryCode}
              errorMessage={item.errorMessage}
            />
          </Grid>
        ))}
        <Grid className="submit-btn-class">
          {loading ? (
            <CircularProgress sx={{ color: "#1a567a" }} />
          ) : (
            <Button handleClick={handleSubmit} label={"Submit"} />
          )}
        </Grid>
      </Grid>
      {openMessagePopUp && (
        <ModalForm
          className="short-modal"
          open={openMessagePopUp}
          handleClose={handleCloseMessagePopUp}
          component={<MessagePopUp />}
        />
      )}
    </div>
  );
}
