import React from "react";
import CryptoJS from "crypto-js";
import _ from "lodash";
import { Grid, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";
import "./mainTemplate.css";
import FAQPage from "../../FAQPage";
import "./FAQButton.css";
import FAQ from "../../assets/FAQ.png";
import Button from "../../utilities/button";
import CustomStepper from "../stepper";
import ModalForm from "../../utilities/modal";
import MagnifyingGlassPopUp from "./magnifyingGlassPopUp";
import { useNavigate } from "react-router-dom";
import { companyDetailsContext } from "../../context";
import {companyDetails} from"../../context";
import { adminDetailsContext } from "../../context";
import { claimPhoneNumberContext } from "../../context";
import { specialCharacterValidation,validateAllowChatDomainURL,isValidPassword,isValidEmail,validateRoleArn, validatePhoneNumber } from "../../preDefinedFunctions/inputFieldValidations.js"
import {
 
  hoursOfOperationsPayloadContext,
  addIntentsContext,
  contactFlowContext,
  loaderContext,
  crmToolTypeContext,
  bulkUserCsvContext,
  mannuallyDetailsContext,
  checkInstaceAvailabilityContext,
  addAgentsContext,
  salesForceStatusContext,
  notFoundStatusContext,
  homePageContext,
  hoursDetailsContext,
} from "../../context";
import { salesForceDetailsContext } from "../../context";
import { getInitialSalesForceDetails } from "../../initialFormValues";
import { axiosInstance } from "../../services/axiosInstance";
import { endpoints } from "../../services/endpoints";
import {
  checkObjectBooleanValues,
  checkObjectBooleanTrueValues,
} from "../../preDefinedFunctions/checkObjectValues";
import { ErrorServiceLogger } from "../../services/errorServiceLogger";
import HoursOperation from "../selectQueues";
import { AlternateEmail } from "@mui/icons-material";
import { callRecordingOptions } from "../selectQueues/bussinessHoursList";

export default function MainTheme({ children,domainURL }) {
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const [openMagnifyingGlassPopUp, setOpenMagnifyingGlassPopUp] =
    React.useState(false);

  const companyDetails = React.useContext(companyDetailsContext);
  const homePageDetails = React.useContext(homePageContext);
  const adminDetails = React.useContext(adminDetailsContext);
  const claimPhoneNumber = React.useContext(claimPhoneNumberContext);
  let salesForceDetails = React.useContext(salesForceDetailsContext);
  const hoursOfOperationsPayload = React.useContext(
    hoursOfOperationsPayloadContext
  );

  const hoursDetails = React.useContext(hoursDetailsContext);
  const contactFlowDetails = React.useContext(contactFlowContext);
  const addIntentsData = React.useContext(addIntentsContext);
  const crmType = React.useContext(crmToolTypeContext);
  const { loader } = React.useContext(loaderContext);
  const bulkUserCsvStatus = React.useContext(bulkUserCsvContext);
  const mannuallyDetails = React.useContext(mannuallyDetailsContext);
  let checkInstanceAvailability = React.useContext(
    checkInstaceAvailabilityContext
  );
  let salesForceStatus = React.useContext(salesForceStatusContext);
  let notFoundStatus = React.useContext(notFoundStatusContext);
  const addAgents = React.useContext(addAgentsContext);
  const [loading, setLoading] = React.useState(loader);
  const [nextButtonDisabled, setNextButtonDisabled] = React.useState(
    checkInstanceAvailability.status
  );
  const [errorMessage, setErrorMessage] = React.useState("");
  const [pageErrorMessage, setPageErrorMessage] = React.useState("");
  const [additionalText, setAdditionalText] = React.useState("");
  const [notFound, setNotFound] = React.useState(notFoundStatus.status);
  const [accessUrl, setAccessUrl] = React.useState(
    companyDetails.companyAccessUrl
  );
  const [domainURLError, setdomainURLError] = React.useState("");
  setTimeout(() => {
    if (checkInstanceAvailability.status) {
      setNextButtonDisabled(checkInstanceAvailability.status);
    }
    setNotFound(notFoundStatus.status);
  }, 100);

  React.useEffect(() => {
    if (pageErrorMessage.length > 0 || errorMessage.length > 0) {
      setTimeout(() => {
        setPageErrorMessage("");
        setErrorMessage("");
      }, 5000);
    }
  }, [pageErrorMessage, errorMessage]);

  const steps = [
    "Organization Details",
    "Claim Phone Number",
    "Administrator Details",
    "Call Handling Options",
    "Add Agents",
    "Connect Your CRM",
  ];

  const handleCloseMagnifyingGlassPopUp = () => {
    setOpenMagnifyingGlassPopUp(false);
  };

  const handleInstanceStatusCheck = () => {
    const payload = {
      // AccessUrl: companyDetails.companyAccessUrl,
      uniqueId: homePageDetails.uId,
      awsAccountNumber: companyDetails.accountNumber,
      roleArn: companyDetails.awsAccountDetails.roleArn,
      companyName: companyDetails.companyName,
      regionName: companyDetails.awsAccountDetails.region,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.companyDetails.instanceStatusCheck, payload, {
        headers: headers,
      })

      .then((response) => {
        // console.log("handleInstanceStatusCheck", response);
        if (response.data.body === "ACTIVE") {
         
          setLoading(false);
          setOpenMagnifyingGlassPopUp(false);
          navigate("/claim-phone-number");

          handleUpdateTenancy();
        } else {
          //handleInstanceStatusCheck();
          setTimeout(handleInstanceStatusCheck, 5000);
        }
      })
      .catch((error) => {
        console.error(error);
        setAdditionalText("It is taking longer than expected.");
        handleInstanceStatusCheck();
      });
  };

  const handleInstanceStorageConfig = async () => {
    const payload = {
      uniqueId: homePageDetails.uId,
      companyName: companyDetails.companyName,
      regionName: companyDetails.awsAccountDetails.region,
      roleArn: companyDetails.awsAccountDetails.roleArn,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.claimPhoneNumber.instanceStorageConfig, payload, {
        headers: headers,
      })
      .then((response) => {
        // console.log("build-bot-response", response);
        if (response.body.statusCode === 200) {
          // console.log(response);
        } else {
          const error = response.data.body;
          ErrorServiceLogger(
            error,
            companyDetails.companyAccessUrl,
            payload,
            endpoints.selectQueues.buildBot
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleSaveCompanyDetailsForm = () => {
    let checkCompanyTypeFalseValues = Object.values(companyDetails.selectCompanyType).every(value => !value);
    let { incomingCalls, outgoingCalls } = companyDetails.contactOptions;
    let checkContactFalseValues = checkObjectBooleanTrueValues({
      incomingCalls,
      outgoingCalls,
    });
  
    // Check for empty or unchecked fields
    if (checkCompanyTypeFalseValues) {
      setErrorMessage("Please select contact center type");
      return;
    }
    if (!companyDetails.companyName) {
      setErrorMessage("Please enter organization name");
      return;
    }
   
    // Validate roleArn format using arnRegex
   
    if (companyDetails.allowChat && !companyDetails.allowChatDomainURL) {
      setErrorMessage("Please enter a domain URL");
      return;
    }
 if(companyDetails.awsAccountDetails.roleArn){
  if(!validateRoleArn(companyDetails.awsAccountDetails.roleArn)){
    setErrorMessage("please enter valid role arn");
    return;
  }
 }

    
   
    // Check instance availability
    if (!checkInstanceAvailability.status) {
      setErrorMessage("Please check availability of access url");
      setNextButtonDisabled(!checkInstanceAvailability.status);
      return;
    }
  
    // Validate domain URL if allowChat is enabled
  if (companyDetails.contactOptions.allowChat) {
    // Trim the domain URL and check if it's empty
    const trimmedDomainURL = companyDetails.allowChatDomainURL.trim();
    if (trimmedDomainURL === "") {
      setErrorMessage("Please enter a domain URL");
      return;
    }

    if (!validateAllowChatDomainURL(trimmedDomainURL)) {
      setErrorMessage("Please provide a valid domain URL");
      return;
    }
  }
  
    // Check for contact options
    if (!checkContactFalseValues) {
      setErrorMessage("Please select at least one contact option (incoming/outgoing calls)");
      return;
    }
  
    // If all validations pass, proceed to the next step
    // Handle next step logic here
    
  
    setErrorMessage("");
    setLoading(true);
    setAdditionalText("This process may take upto 60-90 seconds.");
    setOpenMagnifyingGlassPopUp(true);
    const companyTypes = {
      techSupport: "Tech Support",
      sales: "Sales",
      internalHelpDesk: "Internal Helpdesk",
      others: "others",
    };
    let aliasCompanyName = "";
    if (!companyDetails.companyAliasName.includes("-cc")) {
      aliasCompanyName = companyDetails.companyAliasName + "-cc";
    } else {
      aliasCompanyName = companyDetails.companyAliasName;
    }
    const userDetails = {
      accessUrl: `${companyDetails.companyAccessUrl}`,
      instanceAlias: aliasCompanyName,
      companyName: companyDetails.companyName,
      policyArn:companyDetails.launchStack,
      inboundCalls: companyDetails.contactOptions.incomingCalls || false,
      outboundCalls: companyDetails.contactOptions.outgoingCalls || false,
      allowChat: companyDetails.contactOptions.allowChat || false,
      category:companyTypes[Object.keys(companyDetails.selectCompanyType).find(key => companyDetails.selectCompanyType[key])] ,
      uniqueId: homePageDetails.uId,
      regionName: companyDetails.awsAccountDetails.region,
      awsAccountNumber: companyDetails.accountNumber,
      roleArn: companyDetails.awsAccountDetails.roleArn,
    };
    const body = JSON.stringify(userDetails);
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.companyDetails.detailsSubmit, body, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setTimeout(() => {
            handleInstanceStatusCheck();
          }, 60000);
        } else {
          const error = response.data.body;
          ErrorServiceLogger(
            error,
            body,
            endpoints.companyDetails.detailsSubmit
          );
        }
      })
      .catch((error) => {
        console.error(error);
        ErrorServiceLogger(error, companyDetails.companyAccessUrl);
      });
  };
  // console.log( "domainUrl :", companyDetails.allowChatDomainURL,)
  const handleChatSubmit = () => {
    const body = {
      accessUrl: `${companyDetails.companyAccessUrl}`,
      uniqueId: homePageDetails.uId,
      companyName: companyDetails.companyName,
      regionName: companyDetails.awsAccountDetails.region,
      domainUrl: companyDetails.allowChatDomainURL,
      awsAccountNumber: companyDetails.accountNumber,
      roleArn: companyDetails.awsAccountDetails.roleArn,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.adminDetails.allowChatSubmit, body, {
        headers: headers,
      })
      .then((response) => {
        if (response.statusCode.body.data === 200) {
          // console.log(response);
          handleAllowChatEmail();
        } else {
          const errorMessage = response.data.body;
          ErrorServiceLogger(
            errorMessage,
            companyDetails.companyAccessUrl,
            body,
            endpoints.adminDetails.allowChatSubmit
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAllowChatEmail = () => {
    const body = {
      accessUrl: `${companyDetails.companyAccessUrl}`,
      uniqueId: homePageDetails.uId,
      companyName: companyDetails.companyName,
      domainurl: companyDetails.allowChatDomainURL,
      regionName: companyDetails.awsAccountDetails.region,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.adminDetails.allowChatEmail, body, {
        headers: headers,
      })
      .then((response) => {
        if (response.body.data.statusCode === 200) {
          // console.log(response);
        } else {
          const errorMessage = response.data.body;
          ErrorServiceLogger(
            errorMessage,
            companyDetails.companyAccessUrl,
            body,
            endpoints.adminDetails.allowChatEmail
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAgentActivity = () => {
    const body = {
      // accessUrl: `${companyDetails.companyAccessUrl}`,
      uniqueId: homePageDetails.uId,
      regionName: companyDetails.awsAccountDetails.region,
      companyName: companyDetails.companyName,
      awsAccountNumber: companyDetails.accountNumber,
      roleArn: companyDetails.awsAccountDetails.roleArn,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.adminDetails.agentActivity, body, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          // console.log(response);
        } else {
          const errorMessage = response.data.body;
          ErrorServiceLogger(
            errorMessage,
            companyDetails.companyAccessUrl,
            body,
            endpoints.adminDetails.agentActivity
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCTR = () => {
    const body = {
      accessUrl: `${companyDetails.companyAccessUrl}`,
      awsAccountNumber: companyDetails.accountNumber,
      roleArn: companyDetails.awsAccountDetails.roleArn,
      uniqueId: homePageDetails.uId,
      companyName: companyDetails.companyName,
      regionName: companyDetails.awsAccountDetails.region,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.adminDetails.ctr, body, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          // console.log(response);
        } else {
          const errorMessage = response.data.body;
          ErrorServiceLogger(
            errorMessage,
            companyDetails.companyAccessUrl,
            body,
            endpoints.adminDetails.ctr
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRealTimeMetrics = () => {
    const body = {
      // accessUrl: `${companyDetails.companyAccessUrl}`,
      uniqueId: homePageDetails.uId,
      regionName: companyDetails.awsAccountDetails.region,
      companyName: companyDetails.companyName,
      awsAccountNumber: companyDetails.accountNumber,
      roleArn: companyDetails.awsAccountDetails.roleArn,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.adminDetails.realTimeMetrics, body, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          // console.log(response);
        } else {
          const errorMessage = response.data.body;
          ErrorServiceLogger(
            errorMessage,
            companyDetails.companyAccessUrl,
            body,
            endpoints.adminDetails.realTimeMetrics
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpdateTenancy = () => {
    const body = {
      // accessUrl: `${companyDetails.companyAccessUrl}`,
      uniqueId: homePageDetails.uId,
      companyName: companyDetails.companyName,
      awsAccountNumber: companyDetails.accountNumber,
      tenantTypeValue: companyDetails.awsAccountDetails.installation,
      regionName: companyDetails.awsAccountDetails.region,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.companyDetails.updateTenantTable, body, {
        headers: headers,
      })
      .then((response) => {
      
        if (response.data.statusCode === 200) {
        
        } else {
          const errorMessage = response.data.body;
          ErrorServiceLogger(
            errorMessage,
            companyDetails.companyAccessUrl,
            body,
            endpoints.companyDetails.updateTenantTable
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleBucketCreationSKPIAnalytics = () => {
    const body = {
      // accessUrl: `${companyDetails.companyAccessUrl}`,
      uniqueId: homePageDetails.uId,
      companyName: companyDetails.companyName,
      roleArn: companyDetails.awsAccountDetails.roleArn,
      regionName: companyDetails.awsAccountDetails.region,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.claimPhoneNumber.bucketCreationSKPIAnalytics, body, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          handleInstanceStorageConfig();
          const data =JSON.parse(response.data.body);
        setAccessUrl(data.AccessUrl)  ;
          console.log(setAccessUrl);
         
          // console.log(response);
        } else {
          const errorMessage = response.data.body;
          ErrorServiceLogger(
            errorMessage,
            companyDetails.companyAccessUrl,
            body,
            endpoints.claimPhoneNumber.bucketCreationSKPIAnalytics
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAdminSubmit = () => {
    const es6MobileNumber = adminDetails.phoneNumber;
    if (!adminDetails.firstName) {
      setErrorMessage("Please enter first name");
      return;
    }
    if (!adminDetails.lastName) {
      setErrorMessage("Please enter last name");
      return;
    }
    if (!adminDetails.email) {
      setErrorMessage("Please enter email");
      return;
    }else if(!isValidEmail) {
      setErrorMessage = "Invalid email format";
    }
    if (!adminDetails.phoneNumber) {
      setErrorMessage("Please enter phoneNumber");
      return;
    } else if(!validatePhoneNumber(adminDetails.phoneNumber)){
      setErrorMessage("please eneter phone number correctly");
      return;
    }
 
    if (!adminDetails.userName) {
      setErrorMessage("Please enter user name");
      return;
    }
    if (!adminDetails.password) {
      setErrorMessage("Please enter password");
      return;
    }
  if(adminDetails.email){
      if(!isValidEmail(adminDetails.email)) {
        setErrorMessage("Invalid email pattern");
        return;
      }
    }
 
    if (adminDetails.password) {
      if (!isValidPassword(adminDetails.password)) {
          setErrorMessage("Please provide valid password");
          return;
      }
  }
    if (!adminDetails.confirmPassword) {
      setErrorMessage("Please enter confirm password");
      return;
    }
    setErrorMessage("");
if(adminDetails.password !==adminDetails.confirmPassword){
  setErrorMessage('Password and confirm password are not matching');
  return;
}
setLoading(true);
    const password = adminDetails.password;
    // const encryptedValue = encryptPassword(password);
    const body = {
      firstName: adminDetails.firstName,
      lastName: adminDetails.lastName,
      email: adminDetails.email,
      phoneNumber:"+1"+ es6MobileNumber,
      userName: adminDetails.userName,
      password:  adminDetails.password,
      confirmPassword: adminDetails.confirmPassword,
      companyName: companyDetails.companyName,
      accessUrl: `${companyDetails.companyAccessUrl}`,
      uniqueId: homePageDetails.uId,
      regionName: companyDetails.awsAccountDetails.region,
      // awsAccountNumber: companyDetails.accountNumber,
      roleArn: companyDetails.awsAccountDetails.roleArn,
    };
    if (adminDetails.AlternateName && adminDetails.AlternateEmail) {
      // body.AlternateName = adminDetails.AlternateName;
      body.AlternateEmails = adminDetails.AlternateEmail;
    }
    // if (adminDetails.AlternateName && adminDetails.AlternateEmail) {
    //   body.additionalMails = [`alternateName: ${adminDetails.AlternateName}`, `alternateEmail: ${adminDetails.AlternateEmail}`];
    // }
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.adminDetails.detailsSubmit, body, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setLoading(false);
          navigate("/call-handling-options");

          handleAgentActivity();
          
          handleCTR();
          handleRealTimeMetrics();
          if (companyDetails.contactOptions.allowChat) {
            handleChatSubmit();
          }
        } else {
          setLoading(false);
          setPageErrorMessage(  
            " Admin with the same name already exists try with another UserName."
          );
          const errorMessage = response.data;
          ErrorServiceLogger(
            errorMessage,
            companyDetails.companyAccessUrl,
            body,
            endpoints.adminDetails.detailsSubmit
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSaveClaimPhoneNumberForm = async () => {
    try {
      // if (!claimPhoneNumber.selectPhoneNumber) {
      //   setErrorMessage("Please select phone number and proceed");
      //   return;
      // }
      setLoading(true);
      setErrorMessage("");
      setPageErrorMessage("");
      const selectedPhoneNumber = claimPhoneNumber.selectPhoneNumber;
      const claimphonenumberDetails = {
        selectedPhoneNumber: selectedPhoneNumber,
        accessUrl: `${companyDetails.companyAccessUrl}`,
        uniqueId: homePageDetails.uId,
        companyName: companyDetails.companyName,
        regionName: companyDetails.awsAccountDetails.region,
        awsAccountNumber: companyDetails.accountNumber,
        roleArn: companyDetails.awsAccountDetails.roleArn,
      };
      const headers = {
        "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
      };
      const response = await axiosInstance.post(
        endpoints.claimPhoneNumber.claimNumber,
        JSON.stringify(claimphonenumberDetails), {
          headers: headers,
        }
      );

      if (response.data.statusCode === 200) {
        handleBucketCreationSKPIAnalytics();
        setLoading(false);
        navigate("/administrator-details");
      } else {
        setLoading(false);
        setPageErrorMessage(
          "Selected phone number is not available please select another number and proceed"
        );
        const error = response.data.body;
        ErrorServiceLogger(
          error,
          companyDetails.companyAccessUrl,
          JSON.stringify(claimphonenumberDetails),
          endpoints.claimPhoneNumber.claimNumber
        );
      }
    } catch (error) {
      console.error("Failed to claim phone number:", error);
    }
  };

  const handleSalesforceDetailSubmit = () => {
   // const {id, value, name } = e.target;
    if (!salesForceDetails.apiUserPassword) {
      setErrorMessage("Please enter password");
      return;
    }
  //   if (id === "api-User-Password") {
  //     if (!isValidPassword(value)) {
  //         setErrorMessage({
  //             [id]: "Password must have 1 lowercase, 1 uppercase, 1 number, 1 special character, minimum 8 charecters."
  //         });
  //         return;
  //     }
  //     setErrorMessage("")
  // }
    if (!salesForceDetails.consumerKey) {
      setErrorMessage("Please enter consumer key");
      return;
    }
    if (!salesForceDetails.consumerSecretKey) {
      setErrorMessage("Please enter consumer secret key");
      return;
    }
    if (!salesForceDetails.apiUserAccessToken) {
      setErrorMessage("Please enter access token");
      return;
    }
    if (!salesForceDetails.salesForceApiVersion) {
      setErrorMessage("Please enter sales force API version");
      return;
    }
    if (!salesForceDetails.salesForceDomainURL) {
      setErrorMessage("Please enter sales force domail URL");
      return;
    }
    if (!validateAllowChatDomainURL(salesForceDetails.salesForceDomainURL)) {
      setErrorMessage("Please provide a valid domain URL");
      return;
  }
    setErrorMessage("");
    setLoading(true);
    const body = {
      accessToken: salesForceDetails.apiUserAccessToken,
      password: salesForceDetails.apiUserPassword,
      consumerKey: salesForceDetails.consumerKey,
      consumerSecret: salesForceDetails.consumerSecretKey,
      salesforceVersion: salesForceDetails.salesForceApiVersion,
      salesforceDomainUrl: salesForceDetails.salesForceDomainURL,
      accessUrl: companyDetails.companyAccessUrl,
      uniqueId: homePageDetails.uId,
      companyName: companyDetails.companyName,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.salesForceDetails.detailsSubmit, body, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.ResponseMetadata.HTTPStatusCode === 200) {
          salesForceDetails = getInitialSalesForceDetails;
          salesForceStatus.status = true;
          navigate("/success-page");
          handleSuccessAPI();
          
            handleDetachAPI();
          
        } else {
          const error = response.data.body;
          ErrorServiceLogger(
            error,
            companyDetails.companyAccessUrl,
            body,
            endpoints.salesForceDetails.detailsSubmit
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleBuildBot = () => {
    const payLoad = {
      // AccessUrl: companyDetails.companyAccessUrl,
      uniqueId: homePageDetails.uId,
      regionName: companyDetails.awsAccountDetails.region,
      companyName: companyDetails.companyName,
      // awsAccountNumber: companyDetails.accountNumber,
      roleArn: companyDetails.awsAccountDetails.roleArn,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.selectQueues.buildBot, payLoad, {
        headers: headers,
      })
      .then((response) => {
        if (response.body.statusCode === 200) {
          // console.log(response);
        } else {
          const error = response.data.body;
          ErrorServiceLogger(
            error,
            companyDetails.companyAccessUrl,
            payLoad,
            endpoints.selectQueues.buildBot
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleIntentSubmit = async (count, data) => {
      const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    try {
      if (count < data.length) {
        const response = await axiosInstance.post(
          endpoints.selectQueues.addIntents,
          data[count], {
            headers: headers,
          }
        );
        if (response.data.statusCode === 200) {
          // console.log('add intents response success')
          await handleIntentSubmit(count + 1, data);
        } else {
          const error = response.data.body;
          ErrorServiceLogger(
            error,
            companyDetails.companyAccessUrl,
            data[count],
            endpoints.selectQueues.addIntents
          );
        }
      } else {
        if (companyDetails.contactOptions.allowChat) {
          //handleBuildBot();
        }
      }
    } catch (error) {
      console.error("Something went wrong", error);
    }
  };

  const handleAddIntentsLoopCreation = (data) => {
    handleIntentSubmit(0, data);
    setLoading(false);
    navigate("/add-agents");
  };
  const handlecontactFlowLogsSKPI = () => {
    const payload = {
      roleArn: companyDetails.awsAccountDetails.roleArn,
      region: companyDetails.awsAccountDetails.region,
      UserId: homePageDetails.uId,
      CompanyName: companyDetails.companyName,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.selectQueues.contactFlowLogsSKPI, payload, {
        headers: headers,
      })
      .then((response) => {
        if (response.statusCode.body === 200) {
          // console.log(response);
        } else {
          const error = response.data.body;
          ErrorServiceLogger(
            error,
            payload,
            endpoints.selectQueues.contactFlowLogsSKPI
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleLexlogsSubmit = () => {
    const payload = {
      roleArn: companyDetails.awsAccountDetails.roleArn,
      region: companyDetails.awsAccountDetails.region,
      UserId: homePageDetails.uId,
      CompanyName: companyDetails.companyName,
      awsAccountNumber: companyDetails.accountNumber,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.selectQueues.LexlogosSKPI, payload, {
        headers: headers,
      })
      .then((response) => {
        if (response.statusCode.body === 200) {
          // console.log(response);
        } else {
          const error = response.data.body;
          ErrorServiceLogger(
            error,
            payload,
            endpoints.selectQueues.LexlogosSKPI
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


   //let callRecordingOptions = "No Expiry";
  const handleCreateContactFlow = async () => {
    const payload = {
      accessUrl: hoursOfOperationsPayload.payload.accessUrl,
      RandomHoursOfOperation:
        hoursOfOperationsPayload.payload.RandomHoursOfOperation,
      uniqueId: homePageDetails.uId,
      regionName: companyDetails.awsAccountDetails.region,
      companyName: companyDetails.companyName,
      awsAccountNumber: companyDetails.accountNumber,
      roleArn: companyDetails.awsAccountDetails.roleArn,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    try {
      const response = await axiosInstance.post(endpoints.selectQueues.createContactFlow, payload, {
        headers: headers,
      });
      // console.log("responseContactFlow", response);

      if (response.data.statusCode === 200) {
          if (callRecordingOptions === "No Expiry") {
              // Directly call handleSelectQueuesSubmit if "No Expiry" is selected
              handleSelectQueuesSubmit();
          } else {
              // Call handleCallRecordingOptions if the selected option is not "No Expiry"
              await handleCallRecordingOptions();
              handleSelectQueuesSubmit();
              
          }
      } else {
          const error = response.data.body;
          ErrorServiceLogger(
              error,
              companyDetails.accessUrl,
              payload,
              endpoints.selectQueues.createContactFlow
          );
      }
  } catch (error) {
      console.error("Error in createContactFlow", error);
     
  }
};


  const handleSelectQueuesSubmit = async () => {
    const payload = {
      additionalQueue: hoursOfOperationsPayload.payload.additionalQueue,
      accessUrl: hoursOfOperationsPayload.payload.accessUrl,
      uniqueId: homePageDetails.uId,
      regionName: companyDetails.awsAccountDetails.region,
      companyName: companyDetails.companyName,
      awsAccountNumber: companyDetails.accountNumber,
      roleArn: companyDetails.awsAccountDetails.roleArn,
    };
    console.log("acess",accessUrl);
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    await axiosInstance
      .post(endpoints.selectQueues.createHOPQueue, payload, {
        headers: headers,
      })
      .then((response) => {
        // console.log("responseQueues", response);
        // console.log("addintents", addIntentsData);
        if (response.data.statusCode === 200) {
          if (addIntentsData.addIntents.length > 0) {
            // console.log("addintents2", addIntentsData.addIntents);
            handleAddIntentsLoopCreation(addIntentsData.addIntents);
          } else {
            navigate("/add-agents");
          }
        } else {
          const error = response.data.body;
          ErrorServiceLogger(
            error,
            companyDetails.companyAccessUrl,
            payload,
            endpoints.selectQueues.createHOPQueue
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleCustomPrompt = async (payload) => {
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    await axiosInstance
      .post(endpoints.selectQueues.customPromptHandling, payload, {
        headers: headers,
      })
      .then((response) => {
    

        if (response.data.statusCode === 200) {
         
          // console.log(response);

          setTimeout(() => {
            handleCreateContactFlow();
          }, 15000);
        } else {
          const error = response.data.body;
          ErrorServiceLogger(
            error,
            companyDetails.companyAccessUrl,
            payload,
            endpoints.selectQueues.customPromptHandling
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleGreetingMessage = async (payload) => {
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    await axiosInstance
      .post(endpoints.selectQueues.customPromptHandling, payload, {
        headers: headers,
      })
      // console.log('testing')
      .then((response) => {
      
        if (response.data.statusCode === 200) {
          setTimeout(() => {
            handleCreateContactFlow();
          }, 10000);
          // console.log(response);
         // handleCreateContactFlow();
        } else {
          const error = response.data.body;
          ErrorServiceLogger(
            error,
            companyDetails.companyAccessUrl,
            payload,
            endpoints.selectQueues.customPromptHandling
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleVoiceMail = async (payload) => {
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    await axiosInstance
      .post(endpoints.selectQueues.voiceMailHandling, payload, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.ResponseMetadata.HTTPStatusCode === 200) {
          // console.log(response);
          // handleCreateContactFlow();
        } else {
          const error = response.data.body;
          ErrorServiceLogger(
            error,
            companyDetails.companyAccessUrl,
            payload,
            endpoints.selectQueues.voiceMailHandling
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
// const handleAddAgentsErrorDisplay=()=>{
//   if(!addAgents.)
// }
  const handleContactFlowSubmit = () => {
    // console.log(hoursOfOperationsPayload?.payload);
    if (!hoursDetails.businessHours) {
      setLoading(false);
      setErrorMessage("Please select time zone and bussiness hours");
      return;
    }
    if (!hoursDetails.greetingMessage) {
      setLoading(false); 
      setErrorMessage("Please enter greeting message");
      return;
    }
   
    if (
      hoursDetails.businessHours != "24x7" &&
      _.isEmpty(hoursDetails.callHandling)
    ) {
      setErrorMessage(
        "Please select one option in call handling - after hours"
      );
      return;
    }
    if(!hoursDetails.callRecording){
      setLoading(false);
      setErrorMessage("please  select one option in call Recording" 
      )
      return
    }
    if (
      hoursDetails.callHandling == "Custom prompt message" &&
      !hoursDetails.description
    ) {
      setLoading(false);
      setErrorMessage("Please enter prompt message");
      return;
    }
   
    if (hoursDetails.businessHours === "24x7") {
      setLoading(true);
      handleGreetingMessage(contactFlowDetails.greetingMessagePayload);
    } else if (!_.isEmpty(contactFlowDetails.customPromptPayload)) {
      setErrorMessage("");
      setLoading(true);
      handleCustomPrompt(contactFlowDetails.customPromptPayload);
     
    } 
    if (hoursDetails.callHandling == "Voicemail") {
      setErrorMessage("");
      setLoading(true);
      handleVoiceMail(contactFlowDetails.voiceMailPayload);
    }
  
   // handleBuildBot();
    
handlecontactFlowLogsSKPI();
    // handleCreateContactFlow();
  };



  const handleSuccessAPI = () => {
    const payload = {
      accessUrl: companyDetails.companyAccessUrl,
      uniqueId: homePageDetails.uId,
      regionName: companyDetails.awsAccountDetails.region,
      companyName: companyDetails.companyName,
      roleArn: companyDetails.awsAccountDetails.roleArn,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.successPage.successMail, payload, {
        headers: headers,
      })
      .then((response) => {
          handleBuildBot();
       handleLexlogsSubmit();
  
        if (response.statusCode.body === 200) {
        //  handleBuildBot();
          //handleLexlogsSubmit();
          // console.log(response);
        } else {
          const error = response.data.body;
          ErrorServiceLogger(
            error,
            companyDetails.companyAccessUrl,
            payload,
            endpoints.successPage.successMail
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
// console.log("companyDetails.launchStack",companyDetails.launchStack)
  const handleDetachAPI = async(launchStack) => {
  
    const payload = {
      policyArn:companyDetails.launchStack,
      uniqueId: homePageDetails.uId,
      // companyName:companyDetails.companyName,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
      .post(endpoints.successPage.detachPolicy, payload, {
        headers: headers,
      })
      .then((response) => {
        // console.log("preethi",response)
        if (response.statusCode.body === 200) {
          // console.log("testing1",response)
          const payload = {
      policyArn:launchStack,
      uniqueId: homePageDetails.uId,
      // companyName:companyDetails.companyName,
    };
          // console.log(response.statusCode.body);
        } else {
          const error = response.data.body;
          ErrorServiceLogger(
            error,
            companyDetails.companyAccessUrl,
            payload,
            endpoints.successPage.successMail
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  // const handleLaunchStack = async (e) => {
  //   try {
  //     const body = {
  //       uniqueId: homePageDetails.uId,
  //       roleArn: companyDetails.awsAccountDetails.roleArn,
  //       // companyName:companyDetails.companyName,
  //     //  accessUrl: companyDetails.companyAccessUrl,
  //     };
  // const headers = {
  //   "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
  // };
  //     axiosInstance.post(
  //       endpoints.companyDetails.launchStack,
  //       body, {
        //   headers: headers,
        // }
  //     )
  //     .then((response)=>{
  //       if(response.data.statusCode === 200){
  //         handleDetachAPI()
  //       }

  //     })
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // console.log("recccc", hoursDetails.callRecording);
  const handleCallRecordingOptions = () => {
    return new Promise((resolve, reject) => {
        if (hoursDetails.callRecording === "No Expiry") {
          
            resolve();
            return;
        }
    const payload = {
      // accessUrl: companyDetails.companyAccessUrl,
      uniqueId: homePageDetails.uId,
      // regionName : companyDetails.awsAccountDetails.region,
      companyName: companyDetails.companyName,
      roleArn: companyDetails.awsAccountDetails.roleArn,
      callRecording: hoursDetails.callRecording,
    };
    const headers = {
      "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
    };
    axiosInstance
    .post(endpoints.selectQueues.callRecording, payload, {
      headers: headers,
    })
    .then((response) => {
        if (response.status === 200) {
            // console.log("Call recording API call successful", response.data);
            resolve(response.data);
        } else {
            reject(new Error("Call recording API call failed"));
            const error = response.data.body;
            ErrorServiceLogger(
              error,
              companyDetails.companyAccessUrl,
              payload,
              endpoints.successPage.successMail
            );
          }
     
    })
    .catch((error) => {
        console.error("Error in call recording API call", error);
        reject(error);
    });
});
};
  const handleNext = () => {
    if (pathName === "/organization-details") {
      handleSaveCompanyDetailsForm();
    } else if (pathName === "/claim-phone-number") {
      // if(!companyDetails.companyAccessUrl){
      //   setErrorMessage("Please fill Organization details");
      //   return;
      // }
      handleSaveClaimPhoneNumberForm();
    } else if (pathName === "/administrator-details") {
      handleAdminSubmit();
    } else if (pathName === "/call-handling-options") {
      handleContactFlowSubmit();
    } else if (pathName === "/add-agents") {
    
      if (addAgents.status) {
        
        navigate("/connect-your-crm");
      } else {
        setPageErrorMessage("Please add agents manually or through excel");
        return;
      }
    } else if (pathName === "/connect-your-crm") {
      //   handleBuildBot();
      //  handleLexlogsSubmit();

      navigate("/success-page");
    
      handleSuccessAPI();
     
        handleDetachAPI();
    
      
    } else if (pathName === "/bulk-user-creation") {
      if (bulkUserCsvStatus.status) {
        navigate("/connect-your-crm");
      } else {
        setPageErrorMessage("Please upload excel file");
        return;
      }
    } else if (pathName === "/sales-force-form") {
      handleSalesforceDetailSubmit();
    } else {
      navigate("/");
    }
  };

  const currentStep = steps.findIndex(
    (step) =>
      step.replace(/\s/g, "-").toLowerCase() === pathName.replace(/\//g, "")
  );
  const [faqVisible, setFaqVisible] = React.useState(false);

  const toggleFAQ = () => {
    setFaqVisible(!faqVisible);
  };

  const closeFAQ = () => {
    setFaqVisible(false);
  };

  const handleSkip = () => {
    navigate("/success-page");
    handleSuccessAPI();

      handleDetachAPI();
  
    
   
   };

  return (
    <Grid container className="main-card">
      <Grid item md={2} xs={0} />
      <Grid
        item
        md={8}
        xs={12}
        container
        spacing={1}
        className="connect-container"
      >
        <Grid item md={3} xs={12} className="stepper-container">
          <CustomStepper
            steps={steps}
            activeStep={
              pathName === "/success-page"
                ? 6
                : pathName === "/bulk-user-creation"
                ? 4
                : pathName === "/sales-force-form"
                ? 5
                : currentStep
            }
          />
        </Grid>
        <Grid item md={9} xs={12} className="main-container">
          <Grid item xs={12} className="children-container">
            <div className="children-mob-container">
              {children}
              {errorMessage && (
                <Typography className="error-text">{errorMessage}</Typography>
              )}
              {pageErrorMessage && (
                <Typography className="error-text">
                  {pageErrorMessage}
                </Typography>
              )}
            </div>
          </Grid>
          {!notFound && pathName !== "/success-page" && (
            <Grid
              item
              xs={12}
              className={
                pathName === "/sales-force-form"
                  ? "three-action-buttons"
                  : "action-buttons"
              }
            >
              {pathName !== "/bulk-user-creation" ? (
                <div />
              ) : (
                <Button
                  label={"Back"}
                  handleClick={()=>{
                    navigate("/add-agents");
                  }}
                  className="back-button"
                />
              )}
              {pathName === "/sales-force-form" && (
                <Button
                  label={"Skip"}
                  icon={<DoubleArrowRoundedIcon />}
                  className="skip-button"
                  handleClick={handleSkip}
                />
              )}
              {loading ? (
                <CircularProgress sx={{ color: "#1a567a" }} />
              ) :!nextButtonDisabled ? (
                <Button
                  label={
                    pathName === "/connect-your-crm" ||
                    pathName === "/sales-force-form" 
                      ? "Submit"
                      : "Next"
                  }
                  disabled={!nextButtonDisabled}
                  className={"disabled-next-button"}
                />
              ) : (
                <Button
                  label={
                    pathName === "/connect-your-crm" ||
                    pathName === "/sales-force-form"
                      ? "Submit"
                      : "Next"
                  }
                  handleClick={handleNext}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item md={2} xs={0} />
      {openMagnifyingGlassPopUp && (
        <ModalForm
          className="short-modal"
          open={openMagnifyingGlassPopUp}
          handleClose={handleCloseMagnifyingGlassPopUp}
          component={<MagnifyingGlassPopUp additionalText={additionalText} />}
        />
      )}
      <div className="faq-bar">
        <button className="faq-button" onClick={toggleFAQ}>
          <img src={FAQ} alt="FAQ" className="faq-logo" loading="lazy" />
        </button>
        {faqVisible && (
          <ModalForm
            title={"FAQ'S"}
            open={faqVisible}
            handleClose={closeFAQ}
            showCloseIcon={true}
            component={<FAQPage onClose={closeFAQ} />}
          />
        )}
      </div>
    </Grid>
  );
}
