import React, { useEffect, useState } from "react";
import "./FAQPage.css";
 
const organizationFaqData = [
  {
    question:
      "What should I enter in the “How will your contact center be used” section?",
    answer:
      "This section displays 4 options that describes the main category for which your contact center is going to be used. For a contact center that helps with technical issues and queries, select “Tech Support”. For a contact center that helps with Sales related issues and queries, select “Sales”. For a contact center that acts as an internal help desk within your organization, select “Internal -Helpdesk”. If any of the given options does not suit your requirement, select “Other” and fill out the form that is displayed to customize the category according to your requirement.",
    isOpen: false,
  },
  {
    question:
      "What is Connectgen Account installation?",
    answer:
      "In Connectgen Account installation, only one instance is created per AWS account, dedicated to a single user. With Connectgen Account, think of it as having your own exclusive space – just like renting a standalone apartment where you're the only tenant.",
    isOpen: false,  
  },
  // {
  //   question:
  //     "What is Multi Tenant installation?",
  //   answer:
  //     "Multi Tenant installation allows multiple instances to be created within a single AWS account, accommodating multiple users or tenants. Multi Tenant as more like a shared apartment building. You have multiple tenants (users) sharing the same building (AWS account), each with their own separate space (instance).",
  //   isOpen: false,
  // },
  // {
  //   question:
  //     "How do I choose between Single Tenant and Multi Tenant installations? ",
  //   answer:
  //     "The choice between Single Tenant and Multi Tenant installations depends on factors such as security, resource needs, budget, and scalability. Consider your organization's specific requirements and consult with your IT team or provider for guidance.",
  //   isOpen: false,
  // },
  {
    question:"What is Customer Account installation?",
    answer:" Customer Account installation allows you to use your own AWS account for setting up the instance, giving you more control and flexibility over your resources and configurations. With Customer Account, it's like setting up the office in a building you own. You have complete control over how everything is set up and managed.",
    isOpen:false,
  },
  {
    question:"How do I choose between Connectgen Account and Customer Account installations?",
    answer:" The choice between Connectgen Account and Customer Account installations depends on factors such as security, resource needs, budget, and scalability. Consider your organization's specific requirements and consult with your IT team or provider for guidance.",
    isOpen:false,
  },
  {
    question:
      "What is the region selection option for?",
    answer:
      "The region selection option allows customers to choose the geographic region where they want to deploy their contact center resources.",
    isOpen: false,
  },
  {
    question:
      "Can I change the region of my contact center after it's been deployed?",
    answer:
      "In some cases, it may be possible to migrate your contact center to a different region, but this process can involve downtime and data migration. It's best to plan your region selection carefully from the outset to avoid unnecessary disruptions.",
    isOpen: false,
  },
  {
    question:
      "Can I enter the short form of my organization in the “What is your organization name” section?",
    answer:
      "No, you cannot enter the short form of your organization in this section. The organization name that you give plays an important role in your contact center. Hence, it is required to give a proper full name of your organization.",
    isOpen: false,
  },
  {
    question: "What is an access URL?",
    answer:
      "An access URL is a web address that allows you to visit or access your contact center on the internet.",
    isOpen: false,
  },
  {
    question: "Can I customize my access URL to reflect my organization?",
    answer:
      "Yes, customization of the access URL can be done by editing the URL inside the text box.",
    isOpen: false,
  },
  {
    question: "Is this access URL permanent, or can it be changed later?",
    answer: "Access URL once created cannot be changed.",
    isOpen: false,
  },
  {
    question: "What is the “Check Availability” button for?",
    answer:
      "This button checks for the uniqueness of your Access URL. If any access URLs are same as your Access URL, it shows an error. If your Access URL is unique, it displays “This URL is available” and you can proceed further.",
    isOpen: false,
  },
  {
    question: "What are “Call and chatbot options”?",
    answer:
      "This section displays the call and chatbot features you want to have for your contact center. If you want to allow incoming calls for your contact center, turn on the toggle button for incoming calls. Similarly, if you want to make outgoing calls from your contact center, turn on the toggle button for outgoing calls. If you want to add a chatbot that answers questions to your website, please turn on the toggle button for the Chatbot.",
    isOpen: false,
  },
  {
    question:
      "Why is a domain URL required for creating a chatbot in my application?",
    answer:
      "A domain URL is needed to host and access your chatbot within your application's environment. It provides a unique web address for users to interact with the chatbot.",
    isOpen: false,
  },
  {
    question:
      "What format should the domain URL follow?",
    answer:
      "The domain URL should be in the format “www.example.com” or “example.com”, where “example” is your chosen domain name.",
    isOpen: false,
  },
  {
    question:
      "What are the valid domain extensions accepted for the URL?",
    answer:
      "Valid domain extensions include .com, .co, .in, .info, .net, .org, .digital, .biz, and .online. If your desired domain extension is not listed, reach out to our team.",
      isOpen: false,
  },
  // {
  //   question:"",
  //   answer:"",
  //   isOpen:false,
  // }
];
const claimFaqData = [
  {
    question: "What is the significance of claiming a phone number?",
    answer:
      "Claiming a phone number for the contact center means reserving it exclusively for your use. It ensures that the number is assigned to your contact center and can be used for inbound and outbound calls, providing a dedicated line of communication for your customers.",
    isOpen: false,
  },
  {
    question: " How do I select the country for my phone number?",
    answer:
      "To select the country for your phone number, simply choose the country where you want to establish your contact center operations. This ensures that your phone number is associated with the correct country code, allowing customers to easily reach your contact center.",
    isOpen: false,
  },
  {
    question: "What are the different types of phone numbers available?",
    answer:
      "You can choose between two types of phone numbers: DID (Direct Inward Dialling): This type provides a unique phone number for your contact center, allowing direct inbound calls to that number. Toll-Free Number: This type enables callers to reach your contact center without incurring any charges, with the fees being covered by your organization.",
    isOpen: false,
  },
  {
    question: "How do I choose a specific phone number for my contact center?",
    answer:
      "To select a phone number, browse through the available options based on the country and type selected. Once you find a suitable number, simply click on it to claim it for your contact center. This number will then be assigned to your contact center for communication purposes.",
    isOpen: false,
  },
  {
    question:
      "Are there any additional costs associated with claiming a phone number?",
    answer:
      "While claiming a phone number itself may not incur additional costs, there may be associated charges such as monthly fees or usage charges.",
    isOpen: false,
  },
  {
    question: "How long does it take to activate a claimed phone number?",
    answer: "Phone number activation can occur instantly.",
    isOpen: false,
  },
];
 
const adminFaqData = [
  {
    question: "Who is an administrator?",
    answer:
      "An administrator is in charge of managing and overseeing how your contact center operates. They have the authority to make decisions and changes to ensure everything runs smoothly. Essentially, they are the ones responsible for keeping things organized and working well.",
    isOpen: false,
  },
  {
    question: "What are the roles and responsibilities of an administrator?",
    answer:
      "An administrator is responsible for many things in a contact center. Some of them are Managing the contact center, creating new users, assigning roles, Monitoring the performance and usage of the contact center, Providing training and support to users of the contact center.",
    isOpen: false,
  },
  {
    question: "Which email should I provide in the “Email” section?",
    answer: "You should provide the official email id of the administrator.",
    isOpen: false,
  },
  {
    question: "What is the Password policy for setting up an administrator?",
    answer:
      "Passwords must contain at least one lowercase letter (a-z), one uppercase letter (A-Z), one digit (0-9), and one special symbol.Passwords must be between 8 to 64 characters in length",
    isOpen: false,
  },
  {
    question:"What are alternate emails?",
    answer:"Alternate emails are additional email addresses provided by the customer where important communications, notifications, and updates can be sent.",
    isOpen:false,
  },
  {
    question:"Why should I provide alternate emails? ",
    answer:" Providing alternate emails ensures that you receive important messages even if there is an issue with your primary email address. It helps in maintaining uninterrupted communication.",
    isOpen:false,
  },
];
 
const callHandlingFaqData = [
  {
    question: "What is Hours of operation?",
    answer:
      "Establishing hours of operation enables users to specify the operational hours of your call center. These timings can be selected to accommodate the work schedules of your  organization across different time zones.",
    isOpen: false,
  },
  {
    question: "What is Call handling- after hours?",
    answer:
      "When users call the contact center outside of working hours, you can handle the situation by either sending them an email or playing a custom prompt for them to hear when they call.",
    isOpen: false,
  },
  {
    question: "What is the greeting message, and why should I add it?",
    answer:
      "A greeting message allows us to welcome the user with a predefined message at the beginning of the call.",
    isOpen: false,
  },
  {
    question: "What are queues and why should I select them?",
    answer:
      "To build an Chatbot, you will need to identify a set of actions - known as intents -- that you  want your bot to fulfill. A bot can have multiple intents.For example, a ‘BookTickets’ bot can have intents to make reservations, cancel reservations  and review reservations.Intent name: A descriptive name for the intent Add phrases : How a user might convey the intent Description: How you want to fulfill the intent after the user provides the necessary information.",
    isOpen: false,
  },
  {
    question:"What is the Call Recording - Retention period? ",
    answer:"The retention period is the length of time call recordings are stored in an S3 bucket. You can select the retention period based on your business needs and compliance requirements. This setting is usually configured during the setup of your call recording system.",
    isOpen:false,
  },
  {
    question:"What happens to call recordings after the retention period expires? ",
    answer:"Once the retention period expires, the call recordings will be automatically deleted from the S3 bucket. Once recordings are deleted after the retention period, they cannot be retrieved. It's important to ensure you have backups or have exported necessary recordings before they expire.",
    isOpen:false,
  }
];
 
const addAgentsFaqData = [
  {
    question: "Why should I add agents?",
    answer:
      "Agents are the individuals from your organization who will take calls from users and handle their issues.",
    isOpen: false,
  },
  {
    question: "What is Add manually?",
    answer:
      "You can manually enter the details of the agent by completing the form.",
    isOpen: false,
  },
  {
    question: "What is the User role",
    answer: "The user role refers to the role performed by the agent.",
    isOpen: false,
  },
  {
    question: "What is Import Agents through Excel sheet?",
    answer:
      "You can download a sample Excel file demonstrating how to format agent details. To import  the details of the agents through Excel file.",
    isOpen: false,
  },
];
const crmfaqData = [
  {
    question: "What is the API User Password?",
    answer:
      "The API User Password is like your secret key to access Salesforce data. You can get it by logging into your Salesforce account and going to the settings for the API user. From there, you can set up or change the password.** For further details or assistance, please refer to the guide provided.",
    isOpen: false,
  },
  {
    question: "What is the Consumer Key?",
    answer:
      "The Consumer Secret Key is like the password for your app to talk securely with Salesforce. After creating your connected app, Salesforce will give you this key. Make sure to keep it safe and not share it publicly.** For further details or assistance, please refer to the guide provided.",
    isOpen: false,
  },
  {
    question:
      "Can I use the same Consumer Key and Consumer Secret Key for multiple applications?",
    answer:
      "Its generally recommended to use separate Consumer Key and Consumer Secret Key pairs for each application to enhance security and manage access permissions more effectively.** For further details or assistance ,please refer to the guide provide.",
    isOpen: false,
  },
  {
    question: "What is the API User Access Token?",
    answer:
      "The API User Access Token is a temporary pass that lets your app access Salesforce without using your password every time. You can get it by logging into your Salesforce account and generating a new token under the connected app settings.  ** For further details or assistance, please refer to the guide provided.",
    isOpen: false,
  },
  {
    question: "What happens if my API User Access Token expires?",
    answer:
      "If your API User Access Token expires, your app wont be able to access Salesforce data until a new token is generated. Ensure that your app handles token expiration gracefully and includes mechanisms to automatically refresh tokens when necessary.",
    isOpen: false,
  },
  {
    question: "What is the Salesforce API Version?",
    answer:
      "The Salesforce API Version is like the version number of the tools your app uses to talk to Salesforce. You can specify which version you want your app to use. You can find this information in the Salesforce documentation or by checking your Salesforce account settings.** For further details or assistance, please refer to the guide provided.",
    isOpen: false,
  },
  {
    question: "What is the Salesforce Domain URL?",
    answer:
      "The Salesforce Domain URL is the web address that takes you to your Salesforce account. Its like the address of your house on the internet. You can usually find it by logging into your Salesforce account and checking the web address in your browser address bar.** For further details or assistance, please refer to the guide provided.",
    isOpen: false,
  },
];
const successfaqData = [
  {
    question:
      "What should I do with the access URL? How can I open my contact center using the access URL? ",
    answer:
      "The access URL is the web address to visit your contact center on the internet. To access or visit your contact center, copy the access URL and paste it into the search bar of your web browser. By doing this, you will be able to open your contact center in the browser. ",
    isopen: false,
  },
  {
    question: "My access URL is not working, what should I do now? ",
    answer:
      "If your access URL is not working, try refreshing your web page. If that doesn’t work, you can contact us at: rapidconnect-support@connectgenus.com ",
    isOpen: false,
  },
  {
    question:
      "What is the purpose of username and password? Where and how are they used? ",
    answer:
      "Your username and password serve as the credentials for accessing the Administrator login of your contact center. With these credentials, you can log in to your contact center to make and receive calls ",
    isopen: false,
  },
  {
    question: "I am unable to login using my credentials, what should I now?",
    answer:
      "If you're having trouble logging in with your credentials, feel free to reach out to us at: rapidconnect-support@connectgenus.com ",
    isopen: false,
  },
  {
    question: "What should I do if my contact center number is not working?",
    answer:
      "If your claimed phone number is not working, you can reach out to us at: rapidconnect-support@connectgenus.com.",
    isopen: false,
  },
  {
    question:
      "I cannot see the details of my contact center, what should I do now?",
    answer:
      "If the contact center details are not displayed, please reach out to us at: rapidconnect-support@connectgenus.com",
    isopen: false,
  },
];
 
const FAQPage = ({ onClose }) => {
  let pathName = window.location.pathname;
  const [accordionData, setAccordionData] = useState([]);
 
  useEffect(() => {
    switch (pathName) {
      case "/organization-details":
        setAccordionData(organizationFaqData);
        break;
      case "/claim-phone-number":
        setAccordionData(claimFaqData);
        break;
      case "/administrator-details":
        setAccordionData(adminFaqData);
        break;
      case "/call-handling-options":
        setAccordionData(callHandlingFaqData);
        break;
      case "/add-agents":
        setAccordionData(addAgentsFaqData);
        break;
      case "/connect-your-crm":
        setAccordionData(crmfaqData);
        break;
      case "/sales-force-form":
        setAccordionData(crmfaqData);
        break;
      case "/success-page":
        setAccordionData(successfaqData);
        break;
      case "/bulk-user-creation":
        setAccordionData(addAgentsFaqData);
        break;
      default:
        setAccordionData(organizationFaqData);
    }
  }, []);
 
  const toggleAccordion = (question) => {
    setAccordionData((prevData) =>
      prevData.map((item) =>
        item.question === question ? { ...item, isOpen: !item.isOpen } : item
      )
    );
  };
 
  return (
    <div className="faq-page">
      <div className="faq-content">
        {accordionData.map((item) => (
          <div key={item.question} className="panel panel-default">
            <div
              className="panel-heading"
              role="tab"
              onClick={() => toggleAccordion(item.question)}
            >
              <h4 className="panel-title">
                <a
                  role="button"
                  data-toggle="collapse"
                  aria-expanded={item.isOpen}
                  aria-controls={`collapse${item.question}`}
                >
                  {item.question}
                  <span
                    className={`collapsible-link ${item.isOpen ? "open" : ""}`}
                  ></span>
                </a>
              </h4>
            </div>
            <div
              id={`collapse${item.question}`}
              className={`panel-collapse collapse ${item.isOpen ? "in" : ""}`}
              role="tabpanel"
            >
              <div className="panel-body">
                <p className="answer">{item.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
 
export default FAQPage;