import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Typography } from "@mui/material";
import MainTheme from "../mainTemplate";
import Input from "../../utilities/input";
import TextField from "@mui/material/TextField";
import "./companyDetails.css";
import Label from "../../utilities/label";
import Title from "../../utilities/title";
import Toggle from "../../utilities/toggle";
import ModalForm from "../../utilities/modal";
import Button from "../../utilities/button";
import OthersForm from "./othersForm";
import { axiosInstance } from "../../services/axiosInstance";
import { endpoints } from "../../services/endpoints";
import {
  companyDetailsContext,
  checkInstaceAvailabilityContext,
  homePageContext,
  notFoundStatusContext,
} from "../../context";

 
import {
  removeLSpaceFromString,
  removeRSpacefromString,
} from "../../preDefinedFunctions/removeLRSpacesString";
import { checkObjectBooleanValues } from "../../preDefinedFunctions/checkObjectValues";
import {
  specialCharacterValidation,
  accessUrlValidation,
  validateAllowChatDomainURL,
  validateAwsAccountNumber,
} from "../../preDefinedFunctions/inputFieldValidations";
import InfoIcon from "@mui/icons-material/Info";
import { ErrorServiceLogger } from "../../services/errorServiceLogger";
import GuideForm from "./guidePopUp";
export default function CompanyDetails() {
  let checkInstanceAvailability = React.useContext(
    checkInstaceAvailabilityContext
  );
  const [openGuideForm, setOpenGuideForm] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [openOthersForm, setOpenOthersForm] = React.useState(false);
  let companyDetails = React.useContext(companyDetailsContext);
  let homePageDetails = React.useContext(homePageContext);
  let notFoundStatus = React.useContext(notFoundStatusContext);
  const [contactCenterType, setContactCenterType] = React.useState(
    companyDetails.selectCompanyType
  );
  const regex = /\d/;
  const [responseMessage, setResponseMessage] = React.useState("");
  const [InstallationSelection, setInstallationSelection] = React.useState(
    companyDetails.awsAccountDetails.installation || { installation: "" }
  );
  const [launchStackResponse, setlaunchStackResponse] = React.useState(
    companyDetails.launchStack
  );
  const [awsToken, setAwsToken] = React.useState(companyDetails.awsToken);
  const [companyName, setCompanyName] = React.useState(
    companyDetails.companyName
  );
  const [accountToken, setAccountToken] = React.useState(
    companyDetails.accountToken
  );
  const [accessUrl, setAccessUrl] = React.useState(
    companyDetails.companyAccessUrl
  );
  const [companyAliasName, setCompanyAliasName] = React.useState(
    companyDetails.companyAliasName
  );
  const [contactSupport, setContactSupport] = React.useState(
    companyDetails.contactOptions
  );
  const [awsAccountNumber, setawsAccountNumber] = React.useState(
    companyDetails.accountNumber
  );

  const [roleArn, setroleArn] = React.useState(
    companyDetails.awsAccountDetails.roleArn
  );

  const [checkData, setCheckData] = React.useState("");

  const [selectCompanyError, setSelectOneCompanyTypeError] = React.useState("");
  const [companyError, setCompanyError] = React.useState("");
  const [awsDetailsError, setAwsDetailsError] = React.useState("");
  const [regionError, setRegionError] = React.useState("");
  const [companyAliasNameError, setcompanyAliasNameError] = React.useState("");
  const [accessUrlError, setaccessUrlError] = React.useState("");
  const [checkCompanyUrlError, setCheckCompanyUrlError] = React.useState("");
  const [awsDetails, setAwsDetails] = React.useState(
    companyDetails.awsAccountDetails
  );
  const [awsAccountNumberError, setAwsAccountNumberError] = React.useState("");
  const [domainURLDetails, setDomainURLDetails] = React.useState(
    companyDetails.allowChatDomainURL
  );
  const [domainURLMessage, setDomainURLMessage] = React.useState(false);
  const [domainURLError, setdomainURLError] = React.useState("");
  const [randomString, setRandomString] = React.useState("");
  const [installationType, setInstallationType] = React.useState([]);
  React.useEffect(() => {
    notFoundStatus.status = false;
    setRandomString(generateRandomString());
  }, []);

  React.useEffect(() => {
    if (contactCenterType) {
      companyDetails.selectCompanyType = contactCenterType;
    }
    if (companyName) {
      companyDetails.companyName = companyName;
    }
    if (accessUrl) {
      companyDetails.companyAccessUrl = accessUrl;
    }
    if (contactSupport) {
      companyDetails.contactOptions = contactSupport;
    }
    if (companyAliasName) {
      companyDetails.companyAliasName = companyAliasName;
    }
    if (awsDetails) {
      companyDetails.awsAccountDetails = awsDetails;
    }

    if (awsAccountNumber) {
      companyDetails.accountNumber = awsAccountNumber;
    }

    if (roleArn) {
      companyDetails.awsAccountDetails.roleArn = roleArn;
    }

    if (domainURLDetails) {
      companyDetails.allowChatDomainURL = domainURLDetails;
    }
    if (launchStackResponse) {
      companyDetails.launchStack = launchStackResponse;
    }
    if (awsToken) {
      companyDetails.awsToken = awsToken;
    }
    if (accountToken) {
      companyDetails.accountToken = accountToken;
    }
  }, [
    contactCenterType,
    companyName,
    accessUrl,
    contactSupport,
    companyAliasName,
    companyDetails,
    awsDetails,
    awsAccountNumber,
    roleArn,
    domainURLDetails,
    launchStackResponse,
    accountToken,
    awsToken,
  ]);

  const SelectFromBelowinputFields = React.useMemo(
    () => [
      {
        label: "Tech Support",
        value: contactCenterType.techSupport,
        valueKey: "techSupport",
        type: "button",
        required: true,
        id: "tech-support",
        disabled: false,
      },
      {
        label: "Sales",
        value: contactCenterType.sales,
        valueKey: "sales",
        type: "button",
        required: true,
        id: "sales",
        disabled: false,
      },
      {
        label: "Internal-HelpDesk",
        value: contactCenterType.internalHelpDesk,
        valueKey: "internalHelpDesk",
        type: "button",
        required: true,
        id: "internal-helpdesk",
        disabled: false,
      },
      {
        label: "Other",
        value: contactCenterType.others,
        valueKey: "others",
        type: "button",
        required: true,
        id: "others",
        disabled: false,
      },
    ],
    [contactCenterType]
  );

  const CompanyNameInputFields = React.useMemo(
    () => [
      {
        label: "Company Name",
        value: companyName,
        type: "special-text",
        required: true,
        id: "companyName",
        disabled: false,
        placeholder: "e.g ConnectGen",
        maxLength: 24,
        minLength:4,
      },
    ],
    [companyName]
  );

  const AccessUrlInputFields = React.useMemo(
    () => [
      {
        label: "Access url",
        value: companyAliasName,
        valueKey: "companyAliasName",
        type: "organization-text",
        required: true,
        id: "access-url",
        disabled: false,
        placeholder: "e.g organization-name-ccb",
        maxLength: 24,
        minLength: 4,
      },
    ],
    [companyAliasName]
  );

  const contactInputFields = React.useMemo(
    () => [
      {
        label: "Incoming calls",
        valueKey: "incomingCalls",
        checked: contactSupport.incomingCalls,
        type: "checkbox",
        required: true,
        id: "incoming-calls",
        disabled: false,
      },
      {
        label: "Outgoing calls",
        valueKey: "outgoingCalls",
        checked: contactSupport.outgoingCalls,
        type: "checkbox",
        required: true,
        id: "outgoing-calls",
        disabled: false,
      },
      {
        label: "Allow chatbot",
        valueKey: "allowChat",
        checked: contactSupport.allowChat,
        type: "checkbox",
        required: true,
        id: "allow-chat",
        disabled: false,
      },
    ],
    [contactSupport]
  );

  const AwsAccountDataFields = React.useMemo(
    () => [
      {
        label: "Installation",
        valuekey: "installation",
        type: "select",
        required: true,
        id: "installation",
        disabled: false,
        value: awsDetails.installation,
        placeholder: "Select installation type",
        error: errorMessage,
        options: ["Connectgen Account", "Customer account"],
      },
      {
        label: "Region",
        valueKey: "region",
        type: "select",
        required: true,
        id: "region",
        disabled: false,
        value: awsDetails.region,
        placeholder: "Select region",
        errorMessage: regionError,
        options: [
          { value: "us-east-1", label: "US East (N.Virginia)" },
          { value: "us-west-2", label: "US West (Oregon)" },
        ],
      },
    ],
    [awsDetails]
  );

  const AllowChatDomainField = React.useMemo(
    () => [
      {
        label: "Domain URL",
        valueKey: "domainURL",
        type: "special-text",
        required: true,
        value: domainURLDetails,
        id: "domain-url",
        disabled: false,
        placeholder: "e.g https://connectgenus.com",
        // errorMessage: errorMessage["domainURL"],
      },
    ],
    [domainURLDetails]
  );
console.log("domain",domainURLDetails)
// const handleDomainUrlChange = (e) => {
//   const domainURL = e.target.value.trim();

//   // Validate the domain URL if it is not empty
//   if (domainURL !== "" && !validateAllowChatDomainURL(domainURL)) {
//     setdomainURLError("Please provide a valid domain URL.");
//     setLoading(false); // Adjust loading state if needed
//   } else {
//     setdomainURLError("");
//   }

//   // Update domain URL details if not empty
//   if (domainURL !== "") {
//     setDomainURLDetails(domainURL);
//   } else {
//     // Handle optional logic if needed when domainURL is empty
//    // setDomainURLDetails(""); // Clear domainURLDetails if domainURL is empty
//   }
// };
// const handleDomainUrlChange = (e) => {
//   const input = e.target.value.trim();
//   const requiredPrefix = "https://";

//   // Ensure the input always starts with the required prefix
//   let domainURL = input.startsWith(requiredPrefix) ? input : requiredPrefix + input;

//   // Validate the domain URL if it is not empty
//   if (domainURL !== requiredPrefix && !validateAllowChatDomainURL(domainURL)) {
//     setdomainURLError("Please provide a valid domain URL.");
//     setLoading(false); // Adjust loading state if needed
//   } else {
//     setdomainURLError("");
//   }
//   if (!domainURL.startsWith(requiredPrefix)) {
//         setdomainURLError(`Domain URL must start with "${requiredPrefix}so if needed just change name of your url"`);
//         setLoading(false); // Adjust loading state if needed
//         return;
//      }
  
//   if (domainURL !== requiredPrefix) {
//     setDomainURLDetails(domainURL);
//   } else {
//     // Handle optional logic if needed when domainURL is empty
//     // setDomainURLDetails(""); // Clear domainURLDetails if domainURL is empty
//   }
// };

// const handleDomainUrlChange = (e) => {
//   const domainURL = e.target.value.trim();

//   // Ensure the input starts with "https://"
//   const requiredPrefix = "https://";
//   if (!domainURL.startsWith(requiredPrefix)) {
//     setdomainURLError(`Domain URL must start with "${requiredPrefix}so if needed just change name of your url"`);
//     setLoading(false); // Adjust loading state if needed
//     return;
//   }

//   // Validate the domain URL if it is not empty
//   if (domainURL !== "" && !validateAllowChatDomainURL(domainURL)) {
//    // setdomainURLError("Please provide a valid domain URL.");
//     setLoading(false); // Adjust loading state if needed
//   } else {
//     setdomainURLError("");
//   }

//   // Update domain URL details if not empty
//   if (domainURL !== "") {
//     setDomainURLDetails(domainURL);
//   } else {
//     // Handle optional logic if needed when domainURL is empty
//     // setDomainURLDetails(""); // Clear domainURLDetails if domainURL is empty
//   }
// };
// const handleDomainUrlChange = (e) => {
//   // Extract the full value and trim whitespace
//   const fullValue = e.target.value.trim();

//   // The required prefix
//   const requiredPrefix = "https://";

//   // Ensure the input always starts with "https://"
//   let domainURL;
//   if (!fullValue.startsWith(requiredPrefix)) {
//     domainURL = requiredPrefix + fullValue;
//   } else {
//     domainURL = fullValue;
//   }

//   // Validate the domain URL if it is not empty
//   if (domainURL !== "" && !validateAllowChatDomainURL(domainURL)) {
//     setdomainURLError("Please provide a valid domain URL.");
//     setLoading(false); // Adjust loading state if needed
//   } else {
//     setdomainURLError("");
//   }

//   // Update domain URL details
//   setDomainURLDetails(domainURL);
// };
const handleDomainUrlChange = (e) => {
  // Extract the full value and trim whitespace
  let fullValue = e.target.value.trim();

  // The required prefix
  const requiredPrefix = "https://";

  // Remove any existing "https://" from the input value
  if (fullValue.startsWith(requiredPrefix)) {
    fullValue = fullValue.substring(requiredPrefix.length);
  }
 
  // Combine the required prefix with the sanitized input value
  const domainURL = requiredPrefix + fullValue;

  // Validate the domain URL if it is not empty
  if (domainURL !== "" && !validateAllowChatDomainURL(domainURL)) {
    setdomainURLError("Please provide a valid domain URL.");
    setLoading(false); // Adjust loading state if needed
  } else {
    setdomainURLError("");
  }
  if (!domainURL.startsWith(requiredPrefix)) {
    setdomainURLError(`Domain URL must start with "${requiredPrefix}so if needed just change name of your url"`);
   setLoading(false); // Adjust loading state if needed
   return;
  }
  // Update domain URL details
  setDomainURLDetails(domainURL);
};

  const InstallFields = React.useMemo(
    () => [
      {
        label: "AWS account number",
        valuekey: "awsAccountNumber",
        value: awsDetails.awsAccountNumber,
        type: "accountNumber-type",
        required: true,
        id: "awsAccountNumber",
        disabled: false,
        placeholder: "852812164101",
        maxLength:12,
        // errorMessage: awsAccountNumberError
      },

      {
        label: "Role arn",
        valuekey: "roleArn",
        value: roleArn,
        type: "text",
        required: true,
        id: "roleArn",
        disabled: false,
        placeholder: "arn",
        errorMessage: errorMessage["description"],
      },
    ],

    [roleArn, awsDetails]
  );

  const handleInstallationGetRequest = async (region) => {
    const body = {
      tenantTypeValue: companyDetails?.awsAccountDetails?.installation,
      uniqueId: homePageDetails?.uId,
      regionName: region,
    };

    if (!body.tenantTypeValue || !body.uniqueId) {
      console.error("Invalid request body:", body);
      return;
    }

    const headers = {
      Authorization: `${companyDetails?.awsToken}${companyDetails?.accountNumber}`,
    };

    console.log("Request body:", body);
    console.log("Request headers:", headers);

    try {
      const response = await axiosInstance.post(
        endpoints.companyDetails.tenantType,
        body,
        { headers: headers }
      );
      console.log("Response:", response);
      if (response.data.statusCode === 200) {
        const data = JSON.parse(response.data.body);
        console.log("Parsed data:", data);
        setawsAccountNumber(data.accountId);
        setroleArn(data.roleArn);
        setAwsToken(data.cipherText);
      } else {
        const error = response.data.body;
        console.error("Error response:", error);
        ErrorServiceLogger(error, body, endpoints.companyDetails.tenantType);
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
  };
  // const handleInstallationGetRequest = (region) => {
  //   const body = {
  //     tenantTypeValue: companyDetails.awsAccountDetails.installation,
  //     uniqueId: homePageDetails.uId,
  //     regionName: region,
  //   };
  //   const headers = {
  //     "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
  //   };
  //   axiosInstance
  //     .post(endpoints.companyDetails.tenantType, body, {
  //       headers: headers,
  //     })
  //     console.log("head",headers)
  //     .then((response) => {
  //       if (response.data.statusCode === 200) {
  //         const data = JSON.parse(response.data.body);
  //         console.log("install",response);
  //         setawsAccountNumber(data.accountId);
  //         setroleArn(data.roleArn);
  //         setAwsToken(data.cipherText);
  //         // console.log(awsAccountNumber);
  //       } else {
  //         const error = response.data.body;
  //         ErrorServiceLogger(error, body, endpoints.companyDetails.tenantType);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }

  const handleAwsaccountdetails = async () => {
    const body = {
      uniqueId: homePageDetails.uId,
      awsAccountNumber: companyDetails.accountNumber,
      //roleArn: companyDetails.awsAccountDetails.roleArn,
    };
    const headers = {
      Authorization: `${companyDetails?.awsToken}${companyDetails?.accountNumber}`,
    };

    try {
      const response = await axiosInstance.post(
        endpoints.companyDetails.yourAccount,
        body,
        {
          headers: headers,
        }
      );

      if (response.data.statusCode === 200) {
        const data = JSON.parse(response.data.body);
        // setawsAccountNumber(data.accountId);
        // setroleArn(data.roleArn);
        console.log("test", response.data.body);
        console.log("RESPONSE", response.data.cipherText);
        setAwsToken(data.cipherText);
        console.log("rohi", accountToken);
        // console.log(awsAccountNumber);
      } else {
        const error = response.data.body;
        ErrorServiceLogger(error, body, endpoints.companyDetails.yourAccount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleAwsaccountdetails = () => {

  //   const body = {
  //    uniqueId: homePageDetails.uId,
  //    awsAccountNumber: companyDetails.accountNumber,
  //     //roleArn: companyDetails.awsAccountDetails.roleArn,
  //   };
  //   const headers = {
  //     "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
  //   };
  //   axiosInstance
  //     .post(endpoints.companyDetails.yourAccount, body, {
  //       headers: headers,
  //     })
  //     .then((response) => {
  //       if (response.data.statusCode === 200) {
  //          const data = JSON.parse(response.data.body);
  //        // setawsAccountNumber(data.accountId);
  //        //setroleArn(data.roleArn);
  //        console.log("test",data.body)
  //        console.log("RESPONSE",data.body);
  //         setAwsToken(data.cipherText);
  //         console.log("rohi",accountToken);
  //         // console.log(awsAccountNumber);
  //       } else {
  //         const error = response.data.body;
  //         ErrorServiceLogger(error, body, endpoints.companyDetails.yourAccount);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };
  const handleCompanyNameChange = (e) => {
    const { value } = e.target;
    if (!awsDetails.region && !awsDetails.installation) {
      setAwsDetailsError("Please select installation type and region");
      return;
    }

    if (InstallationSelection.installation === "Customer account") {
      if (!awsDetails.accountNumber && !awsDetails.roleArn) {
        setAwsDetailsError("Please enter account number and role ARN");

        return;
      } 
  
        else if (!awsDetails.region) {
        setRegionError("Please select region");
        return;
      } else {
        setRegionError("");
      }
    } else if (!awsDetails.installation) {
      setAwsDetailsError("Please select installation type");
      return;
    } else if (!awsDetails.region) {
      setRegionError("Please select region");
      return;
    } else {
      setRegionError("");
      setAwsDetailsError("");
    }
    setCompanyError(companyName.length);
    let companyInputValue = e.target.value;
    if (!specialCharacterValidation(companyInputValue)) {
      setCompanyError(
        "Please enter valid company name. Special characters are not allowed[ex:-@,.-])"
      );
    } else {
      setCompanyError("");
    }
    // if (companyName.length<3) {
    //   setCompanyError("Company name should be between 4 - 24 characters only ");
    // } else {
    //   setCompanyError(" ");
    // }

    // let companyNameWithoutSpaces = removeLSpaceFromString(companyInputValue);
    const filteredValue = value.replace(/[^a-zA-Z ]/g, "");
    setCompanyName(filteredValue);
    // setCompanyName(companyNameWithoutSpaces);

    setCompanyAliasName(filteredValue.split(" ").join("-").toLowerCase());
  };

  const generateRandomString = () => {
    const characters = "bcdfghjklmnpqrstvwxyz";
    let result = "";
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleCompanyAcessUrlChange = (e) => {
    let accessCompanyName = removeRSpacefromString(companyName);
    accessCompanyName = accessCompanyName.replace(/\s+/g, " ");
    setCompanyName(accessCompanyName);
    accessCompanyName = accessCompanyName.toLowerCase();
    accessCompanyName = accessCompanyName.split(" ").join("-");
    setCompanyAliasName(accessCompanyName);
    setAccessUrl(
      `https://${accessCompanyName}-ccb-${randomString}.my.connect.aws`
    );
  };

  const handleCompanyNameAccessURL = (e) => {
    setCheckData([""]);
    let aliasCompanyName = `${e.target.value
      .split(" ")
      .join("-")
      .toLowerCase()}`;
    let companyInputValue = e.target.value;

    if (!accessUrlValidation(aliasCompanyName)) {
      setaccessUrlError(
        "Please enter valid url. Special characters are not allowed[ex:-@,.-])"
      );
    } else {
      setaccessUrlError("");
    }
    if (!specialCharacterValidation(aliasCompanyName)) {
      setAccessUrl("");
    }
    setCompanyAliasName(aliasCompanyName);
    setAccessUrl(
      `https://${aliasCompanyName}-ccb-${randomString}.my.connect.aws`
    );
  };

  const handleCompanyNameAccessURLFocusOut = () => {
    let aliasCompanyName = `${companyAliasName}-ccb-${randomString}`;
    setCompanyAliasName(aliasCompanyName);
    setAccessUrl(`https://${aliasCompanyName}.my.connect.aws`);
  };

  const handleCompanyNameAccessFocusIn = () => {
    if (companyAliasName.includes("-ccb")) {
      let aliasName = companyAliasName.split("-ccb")[0];
      setCompanyAliasName(aliasName);
    }
  };


  const handleOnChange = (e) => {
    const { name, id, value } = e.target;

    if (id in contactCenterType) {
      setContactCenterType((prevType) => {
        const updatedState = {};
        // Set the clicked button's state to true and others to false
        Object.keys(prevType).forEach((key) => {
          updatedState[key] = key === e.target.id;
        });

        // Log the updated state
        console.log("type", e.target.id);

        return updatedState;
      });
      // if (id === "awsAccountNumber") {
      //   handleValidatePhoneNumber(id, value);
      // }
      if (id === "others") {
        setOpenOthersForm((prev) => !prev);
      }

      setSelectOneCompanyTypeError("");
      return;
    }

    let checkFalseValues = Object.values(contactCenterType).every(
      (value) => !value
    );

    if (checkFalseValues) {
      setSelectOneCompanyTypeError(
        "Please select any contact center type from above before proceeding with installation type"
      );
      return;
    } else {
      setSelectOneCompanyTypeError("");
      // Proceed with your form submission or other actions
    }

    if (contactCenterType.others) {
      setSelectOneCompanyTypeError(
        "Please select any contact center type from above (Tech support/Sales/Internal-helpdesk) before proceeding with installation type"
      );
      return;
    }

    // setSelectOneCompanyTypeError("");

    if (name === "region") {
      if (!awsDetails.installation) {
        setAwsDetailsError("Please select installation type first");
        return;
      }
      
    } else {
      setAwsDetailsError("");
    }
    // if (name === "region") {
    //   if (InstallationSelection.installation === "Your account") {
    //     if (!awsDetails.accountNumber || !awsDetails.roleArn) {
    //       setRegionError("Please enter account number and roleARN");
    //       // Clear the region value when there's an error
    //       setAwsDetails({ ...awsDetails, region: "" });
    //       return;
    //     } else {
    //       setRegionError("");
    //       // Proceed to set the region value when there's no error
    //       // For example:
    //       // setAwsDetails({ ...awsDetails, region: selectedRegion });
    //       return;
    //     }
    //   } else {
    //     // Clear any existing errors when installation type is not "Your account"
    //     setRegionError("");
    //     // Proceed to set the region value directly
    //     // For example:
    //     // setAwsDetails({ ...awsDetails, region: selectedRegion });
    //     return;
    //   }
    // }
    

    if (e.target.id === "roleArn") {
      if (companyDetails.accountNumber) {
        setroleArn(value);
        handleAwsaccountdetails(value);
      }}

    setAwsDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (e.target.id === "awsAccountNumber") {
      setawsAccountNumber(value);
    }

    if (name === "installation") {
      setInstallationSelection((prev) => ({
        ...prev,
        installation: value,
      }));
      setAwsDetails((prev) => ({
        ...prev,
        [name]: value,
        region: "",
      }));
    }
    // if (!id === "roleArn") {
    //   const arnRegex = /^arn:aws:iam::\d{12}:role\/[a-zA-Z_0-9+=,.@\-_/]+$/;
    //   if (!arnRegex.test(value)) {
    //     setErrorMessage({ description: "Invalid Role ARN format" });
    //   } else {
    //     setErrorMessage({ description: "" });
    //   }
    // }
    if (id === "roleArn") {
      const arnRegex = /^arn:aws:iam::\d{12}:role\/[a-zA-Z_0-9+=,.@\-_/]+$/;
      const invalidFormatMessage = "Invalid Role ARN format";
  
      // Check if value is empty or does not match the ARN regex
      if (value === "" || !arnRegex.test(value)) {
        setErrorMessage({ description: invalidFormatMessage });
      } else {
        // Check for specific incorrect patterns
        const parts = value.split(':');
        if (parts.length < 6 || parts[0] !== 'arn' || parts[1] !== 'aws' || parts[2] !== 'iam' || parts[3] !== '' || !/^\d{12}$/.test(parts[4]) || !parts[5].startsWith('role/')) {
          setErrorMessage({ description: invalidFormatMessage });
        } else {
          setErrorMessage({ description: "" });
        }
      }
    }
    if (e.target.id === "roleArn" && !awsAccountNumber) {
      setAwsAccountNumberError("Enter AWS Account Number");
      setroleArn("");
    } else {
      setAwsAccountNumberError("");
    }
    if (id === "awsAccountNumber") {
      if (!/^\d{12}$/.test(value)) {
        setAwsAccountNumberError(
          "Please enter a valid AWS account number. It must be exactly 12 digits."
        );
      } else {
        setAwsAccountNumberError("");
      }
    }

    if (name === "region") {
      if (InstallationSelection.installation === "Connectgen Account") {
        handleInstallationGetRequest(value);
      } else {
        // handleAwsaccountdetails(value);
        // handleInstallationPostRequest(value);
        //handleAwsaccountdetails(value);
      }
    }
    // if(InstallationSelection.installation ==="Your account"){
    //   handleAwsaccountdetails(value);
    // }
//    if (id === "domain-url" && value.trim() !== "") {
//   if (!validateAllowChatDomainURL(value)) {
//     setdomainURLError("Please provide a valid domain URL.");
//     setLoading(false);
//   } else {
//     setdomainURLError("");
//   }
// }

    if (name === "installation" && value === "Customer Account") {
      setroleArn("");

      setCompanyName("");
      setAccessUrl("");
      setCompanyAliasName("");
    }
    setRegionError("");
    setAwsDetailsError("");
  };

  const handleLaunchStack = async () => {
    try {
      const body = {
        uniqueId: homePageDetails.uId,
        roleArn: companyDetails.awsAccountDetails.roleArn,
        // companyName:companyDetails.companyName,
        //  accessUrl: companyDetails.companyAccessUrl,
      };
      const headers = {
        Authorization: companyDetails.awsToken + companyDetails.accountNumber,
      };

      const response = await axiosInstance.post(
        endpoints.companyDetails.launchStack,
        body,
        {
          headers: headers,
        }
      );
      // console.log('rohi',response)
      if (response.data.statusCode === 200) {
        const data = response.data.body;
        // console.log('rohi1',data)
        setlaunchStackResponse(data);
      } else {
        const errorMessage = response.data.body;
        ErrorServiceLogger(
          errorMessage,
          body,
          endpoints.companyDetails.launchStack
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log(
    "companyDetails.awsAccountDetails.roleArn",
    companyDetails.awsAccountDetails.roleArn
  );
  // console.log('rohitha',companyDetails.launchStack)
  const handleCloseOthersForm = () => {
    setOpenOthersForm(!openOthersForm);
  };
  const handleOnToggleChange = (e) => {
    if (checkData.status !== "success") {
      setCheckCompanyUrlError(
        "Before proceeding, please `check availability` of your access url"
      );
      return;
    }
    setContactSupport({
      ...contactSupport,
      [e.target.id]: !contactSupport[e.target.id],
    });
  };
  const openGuideLink = () => {
    setOpenGuideForm(true);
  };
  const handleCloseGuideForm = () => {
    setOpenGuideForm(false);
  };
  const handleButtonClick = async () => {
    if (companyName.length > 3) {
      setLoading(true); 
  
      try {
        await handleLaunchStack();
        handleClickSubmit();
      } catch (error) {
        console.error('Error in handleLaunchStack:', error);
      }
    }
  };

  // const handleClickSubmit = () => {
  //   if (!companyName) {
  //     setCompanyError("Please enter organization name");
  //     return;
  //   }
  //   let aliasCompanyName = "";

  //   if (!companyAliasName.includes("-ccb")) {
  //     aliasCompanyName = companyAliasName + "-ccb" + "-" + `${randomString}`;
  //   } else {
  //     aliasCompanyName = companyAliasName;
  //   }
  //   if (companyAliasName.length < 4) {
  //     setcompanyAliasNameError("Access url must contain 4 to 45 characters");
  //     return;
  //   } else {
  //     setcompanyAliasNameError("");
  //   }
  //   setCompanyAliasName(aliasCompanyName);

  //   // const uId = generateUniqueID();
  //   // setUniqueId(uId);
  //   const body = {
  //     accessUrl: aliasCompanyName,
  //     regionName: companyDetails.awsAccountDetails.region,
  //     uniqueId: homePageDetails.uId,
  //     roleArn: companyDetails.awsAccountDetails.roleArn,

  //   };
  //   const headers = {
  //     "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
  //   };

  //   console.log('auth',  companyDetails.awsToken+ companyDetails.accountNumber )
  //   axiosInstance
  //     .post(endpoints.companyDetails.checkAccessURLInstance, body, {
  //       headers: headers,
  //     })
  //     console.log("head",headers)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         // console.log("Response", response.data);
  //         let statusCheck = {
  //           message: response.data.message,
  //           status: "success",
  //         };
  //         setResponseMessage(response.data.message);
  //         // console.log(statusCheck);
  //         setCheckData(statusCheck);
  //         setCheckCompanyUrlError("");
  //         checkInstanceAvailability.status = true;
  //         setLoading(false);

  //       } else {
  //         const error = response.data.body;
  //         ErrorServiceLogger(
  //           error,
  //           body,
  //           endpoints.companyDetails.checkAccessURLInstance,
  //           homePageDetails.uId
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };
  const handleClickSubmit = async () => {
    if (!companyName) {
      setCompanyError("Please enter organization name");
      return;
    }
    let aliasCompanyName = "";

    if (!companyAliasName.includes("-ccb")) {
      aliasCompanyName = companyAliasName + "-ccb" + "-" + `${randomString}`;
    } else {
      aliasCompanyName = companyAliasName;
    }
    if (companyAliasName.length < 4) {
      setcompanyAliasNameError("Access url must contain 4 to 45 characters");
      return;
    } else {
      setcompanyAliasNameError("");
    }
    setCompanyAliasName(aliasCompanyName);

    const body = {
      accessUrl: aliasCompanyName,
      regionName: companyDetails.awsAccountDetails.region,
      uniqueId: homePageDetails.uId,
      roleArn: companyDetails.awsAccountDetails.roleArn,
    };
    const headers = {
      Authorization: companyDetails.awsToken + companyDetails.accountNumber,
    };

    console.log("auth", companyDetails.awsToken + companyDetails.accountNumber);
    console.log("head", headers);

    try {
      const response = await axiosInstance.post(
        endpoints.companyDetails.checkAccessURLInstance,
        body,
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        let statusCheck = {
          message: response.data.message,
          status: "success",
        };
        setResponseMessage(response.data.message);
        setCheckData(statusCheck);
        setCheckCompanyUrlError("");
        checkInstanceAvailability.status = true;
        setLoading(false);
      } else {
        const error = response.data.body;
        ErrorServiceLogger(
          error,
          body,
          endpoints.companyDetails.checkAccessURLInstance,
          homePageDetails.uId
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <MainTheme>
      <Title title={"How will your contact center be used?"} />
      <div className="organization-details-section">
        <Grid container spacing={2} className="conatiner-section">
          <Grid item xs={12} className="organization-titles">
            <Label title={"Select one from below"} />
          </Grid>
          {SelectFromBelowinputFields.map((item, index) => (
            <Grid item md={3} xs={6} className="input-company-div">
              <Input
                type={item.type}
                disabled={item.disabled}
                required={item.required}
                key={index}
                id={item.valueKey}
                label={item.label}
                value={item.value}
                handleOnChange={handleOnChange}
              />
            </Grid>
          ))}

          {selectCompanyError && (
            <Grid item xs={12} className="error-div">
              <Typography className="error-text">
                {selectCompanyError}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container className="extra-details-container">
          <Grid item xs={12} className="account-titles"></Grid>
          {AwsAccountDataFields.map((item, index) => (
            <Grid item md={4} xs={10} key={index} className="input-div-class">
              <Label title={item.label} />
              <Input
                type={item.type}
                disabled={item.disabled}
                required={item.required}
                key={index}
                id={item.id}
                label={item.label}
                value={item.value}
                handleOnChange={handleOnChange}
                options={item.options}
                placeholder={item.placeholder}
                errorMessage={item.errorMessage}
              />
              {item.valueKey === "region" && regionError && (
                <Typography className="error-text">{regionError}</Typography>
              )}
            </Grid>
          ))}
          {awsDetailsError && (
            <Grid item xs={12} className="error-div">
              <Typography className="error-text">{awsDetailsError}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid container className="extra-details-container">
          <Grid item xs={12} className="account-titles"></Grid>
          {InstallationSelection.installation === "Customer account" &&
            InstallFields.map((item, index) => (
              <Grid item md={4} xs={12} key={index} className="input-div-class">
                <Label title={item.label} />
                <Input
                  type={item.type}
                  disabled={item.disabled}
                  required={item.required}
                  key={index}
                  id={item.id}
                  label={item.label}
                  value={item.value}
                  handleOnChange={handleOnChange}
                  options={item.options}
                  placeholder={item.placeholder}
                  maxLength={item.maxLength}
                  errorMessage={item.errorMessage}
                />
              </Grid>
            ))}
          {InstallationSelection.installation === "Customer account" && (
            <p className="guide-style" onClick={openGuideLink}>
              How to set up role ARN
            </p>
          )}
          {awsAccountNumberError && (
            <Grid item xs={12} className="error-div">
              <Typography className="error-text">
                {awsAccountNumberError}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container className="company-name-container">
          <Grid item xs={12} className="organization-titles">
            <Label title={"What is your organization name?"} />
          </Grid>
          {CompanyNameInputFields.map((item, index) => (
            <Grid item md={9} xs={12} className="input-company-div">
              <Input
                type={item.type}
                disabled={item.disabled}
                required={item.required}
                key={index}
                id={item.id}
                label={item.label}
                value={item.value}
                handleOnChange={handleCompanyNameChange}
                checked={item.checked}
                placeholder={item.placeholder}
                handleFocusOut={handleCompanyAcessUrlChange}
                maxLength={item.maxLength}
                minLength={item.minLength}
                errorMessage={companyError}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container className="company-name-url-container">
          <Grid item xs={12} className="organization-titles">
            <Label title={"Create your access URL"} />
          </Grid>
          {AccessUrlInputFields.map((item, index) => (
            <Grid item md={12} xs={12} className="access-url-container">
              <Grid item md={9} xs={12} className="access-url-div">
                <Grid item md={2} xs={2} className="left-access-url">
                  <p>https://</p>
                </Grid>
                <Grid item md={6} xs={8} className="middle-access-url">
                  <Input
                    value={item.value}
                    type={item.type}
                    disabled={item.disabled}
                    required={item.required}
                    key={index}
                    id={item.valueKey}
                    // label={item.label}
                    handleOnChange={handleCompanyNameAccessURL}
                    placeholder={item.placeholder}
                    maxLength={item.maxLength}
                    handleFocusOut={handleCompanyNameAccessURLFocusOut}
                    handleFocusIn={handleCompanyNameAccessFocusIn}
                    errorMessage={companyAliasNameError}
                  />
                </Grid>
                <Grid item md={3} xs={6} className="right-access-url">
                  <p className="right-css">.my.connect.aws</p>
                </Grid>
              </Grid>
              <Grid item md={3} xs={6} className="check-button">
                {loading ? (
                  <CircularProgress
                    className="loading-csv-button"
                    sx={{
                      color: "#1a567a",
                      width: "5px",
                      height: "5px",
                      marginRight: "60px",
                    }}
                  />
                ) : (
                  <Button
                    label="Check Availability"

                    handleClick={handleButtonClick}
                    disabled={
                      accessUrlError.length > 0 ||
                      !companyAliasName.length > 12||
                      responseMessage.length > 0 ||
                      !companyName ||
                      companyName.length > 25
                        ? true
                        : false
                    }
                    className={
                      accessUrlError.length >0 ||
                      companyAliasName.length < 4 ||
                      responseMessage.length > 0 ||
                      !companyName ||
                      companyName.length > 24
                        ? "disable-check-available-button"
                        : "check-available-button"
                    }
                  />
                )}
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} className="error-div">
            <Typography className="error-text">
              {checkCompanyUrlError && checkCompanyUrlError}
            </Typography>
          </Grid>
          <Grid item xs={12} className="error-div">
            <Typography className="error-text">
              {accessUrlError && accessUrlError}
            </Typography>
            {checkData && checkData?.status === "success" ? (
              <Typography className="success-text">
                {checkData.message}
              </Typography>
            ) : (
              <Typography className="error-text">
                {checkData.message}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} className="conatiner-section">
          <Grid item xs={12} className="organization-title">
            <Label title={"Call and chatbot options"} />
          </Grid>
          {contactInputFields.map((item, index) => (
            <Grid item className="contact-input-div">
              <Toggle
                type={item.type}
                disabled={item.disabled}
                required={item.required}
                key={index}
                id={item.valueKey}
                label={item.label}
                handleOnChange={handleOnToggleChange}
                checked={item.checked}
              />
            </Grid>
          ))}
        </Grid>
        {contactSupport.allowChat && (
          <Grid container className="allowchat-domainurl-container">
            <Grid item xs={12} className="domainURL-title">
              <Label title={"Domain url"} />
              {/* <InfoIcon
                className="infoIcon"
                onMouseEnter={() => setDomainURLMessage(true)}
                onMouseLeave={() => setDomainURLMessage(false)}
        /> */}
            </Grid>
            {AllowChatDomainField.map((item, index) => (
              <Grid
                item
                md={9}
                xs={12}
                key={index}
                className="input-domainurl-div"
              >
                <Input
                  type={item.type}
                  disabled={item.disabled}
                  required={item.required}
                  id={item.id}
                  label={item.label}
                  value={item.value}
                  placeholder={item.placeholder}
                  handleOnChange={handleDomainUrlChange}
                />
              </Grid>
            ))}
            {/* {domainURLMessage && (
            <TextField
                variant="outlined"
                InputProps={{ className: "customInput" }}
                multiline
                value="https://www.connectgenus.com/team_category/specialists/ "
            />
        )} */}
            {domainURLError && (
              <Grid item xs={12} className="error-div">
                <Typography className="error-text">{domainURLError}</Typography>
              </Grid>
            )}
          </Grid>
        )}
        {openOthersForm && (
          <ModalForm
            title={"Fill the below details"}
            open={openOthersForm}
            handleClose={handleCloseOthersForm}
            showCloseIcon={true}
            component={<OthersForm handleClose={handleCloseOthersForm} />}
          />
        )}
        <ModalForm
          title={"Guidelines"}
          open={openGuideForm}
          handleClose={() => setOpenGuideForm(false)}
          showCloseIcon={true}
          component={<GuideForm handleClose={handleCloseGuideForm} />}
        />
      </div>
    </MainTheme>
  );
}
