import * as React from "react";
import { Grid } from "@mui/material";
import Input from "../../utilities/input";
import Label from "../../utilities/label";
import Button from "../../utilities/button";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  additionalQueueDetailsContext,
  companyDetailsContext,
  addIntentsContext,homePageContext
} from "../../context";
import "./intentForm.css";

export default function IntentForm({ handleClose, intentList }) {
  const additionalQueues = React.useContext(additionalQueueDetailsContext);
  const addIntentsArray = React.useContext(addIntentsContext);
  const companyDetails = React.useContext(companyDetailsContext);
  const homePageDetails = React.useContext(homePageContext);
  const [intentDetails, setIntentDetails] = React.useState({
    intentName: "",
    addPhrases: "",
    description: "",
  });
  const [errorMessage, setErrorMessage] = React.useState({});
  const [phrases, setPhrases] = React.useState([]);

  const handleOnChange = (e) => {
    const { id, value } = e.target;
    let errorMessage = {};
    if (id === "intentName" && (/[^a-zA-Z_]/.test(value))) {
      errorMessage[id] =
        "Numbers, spaces and special characters are not allowed";
    }
    if (id === "intentName") {
      setIntentDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value.toLowerCase(),
      }));
    } else {
      setIntentDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    }
    setErrorMessage(errorMessage);
  };
  const handleIntentFormValidation = (formData) => {
    const errors = {};
    const { intentName, description, phrases } = formData;
  
    if (!intentName) {
      errors["intentName"] = "Please enter intent name";
    } else if (/[^a-zA-Z_]/.test(intentName)) {
      errors["intentName"] = "Numbers, spaces and special characters are not allowed";
    }
  
    if (!phrases || phrases.length === 0) {
      errors["addPhrases"] = "Please enter phrases";
    }
  
    if (!description) {
      errors["description"] = "Please enter description";
    }
  
    setErrorMessage(errors);
    return Object.keys(errors).length === 0;
  };
  

  const handleSave = () => {
    let errors = {}; 
    const isValid = handleIntentFormValidation(intentDetails);
    if (!intentDetails.intentName) {
      errors["intentName"] = "Please enter intent name";
    }else if (/[^a-zA-Z_]/.test(intentDetails.intentName)) {
      errors["intentName"] = "Numbers, spaces and special characters are not allowed";
    }
    if (phrases.length === 0) {
      errors["addPhrases"] = "Please add phrases";
    }
    if (!intentDetails.description) {
      errors["description"] = "Please enter description";
    }
    setErrorMessage(errors);
    if (isValid || Object.keys(errors).length === 0) {
      let intentPhraseLength =
        3 + additionalQueues.length + addIntentsArray.addIntents.length + 1;
      const phrasesPayload = phrases.flat().join(",");
      const intentPayload = {
        accessUrl: companyDetails.companyAccessUrl,
        intentName: intentDetails.intentName,
        description: intentDetails.description,
        sampleUtterances: [phrasesPayload],
        uniqueId : homePageDetails.uId,
        regionName:companyDetails.awsAccountDetails.region,
        companyName: companyDetails.companyName,
        awsAccountNumber: companyDetails.accountNumber,
        roleArn: companyDetails.awsAccountDetails.roleArn,
      };
      let existingPhrases = intentList.flatMap((intent) =>
        intent.sampleUtterances.flat()
      );
      existingPhrases = existingPhrases.map((item) => item.split(","));
      existingPhrases = existingPhrases.flat(1);
      const newPhrases = phrasesPayload
        .split(",")
        .map((phrase) => phrase.trim());
      const hasDuplicatePhrase = newPhrases.some((phrase) =>
        existingPhrases.includes(phrase)
      );

      const isUniqueIntentName = !intentList.some(
        (intent) => intent.intentName === intentDetails.intentName
      );

      if (hasDuplicatePhrase || !isUniqueIntentName) {
        if (hasDuplicatePhrase) {
          errors = {
            ...errors,
            addPhrases: "Each intent must have unique phrases",
          };
        }
        if (!isUniqueIntentName) {
          errors = {
            ...errors,
            intentName: "Each intent must have a unique name",
          };
        }
        setErrorMessage(errors);
        return;
      }
      intentList.push(intentPayload);
      handleClose();
    }
  };

  const handleIntentInputChange = (event) => {
    const { value } = event.target;

    setIntentDetails({
      ...intentDetails,
      addPhrases: value,
    });
    if (value.includes(",") || event.key === "Enter") {
      event.preventDefault();
      const phrasesArray = value
        .split(/,|\n|\r\n/)
        .map((phrase) => phrase.trim())
        .filter(Boolean);

      setPhrases([...phrases, phrasesArray]);
      setIntentDetails({
        ...intentDetails,
        addPhrases: "",
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const { value } = event.target;
      const trimmedValue = value.trim();
      const hasSpecialCharacters = /[^\w\s?!,]/.test(trimmedValue);
      if (!trimmedValue) {
        setErrorMessage({ addPhrases: "Please enter phrase" });
        return;
      }
      if (hasSpecialCharacters) {
        setErrorMessage({
          addPhrases:
            "Phrases cannot contain special characters except ? and !",
        });
        return;
      }
      const phrasesArray = value
        .split(/,|\n|\r\n/)
        .map((phrase) => phrase.trim())
        .filter(Boolean);

      setPhrases([...phrases, phrasesArray]);
      setIntentDetails({
        ...intentDetails,
        addPhrases: "",
      });
      setErrorMessage({ addPhrases: "" });
    } else {
      setErrorMessage({ addPhrases: "Please click enter" });
    }
  };
  const removePhrase = (phraseIndex) => {
    const newPhrases = phrases.filter((word, i) => i !== phraseIndex);
    setPhrases(newPhrases);
  };

  const IntentDetailsInputFields = React.useMemo(
    () => [
      {
        label: "Intent name",
        valueKey: "intentName",
        type: "text",
        required: true,
        id: "intent-name",
        disabled: false,
        placeholder: "Enter intent name",
        value: intentDetails.intentName,
        handleOnChange: handleOnChange,
        errorMessage: errorMessage["intentName"],
      },
      {
        label: "Add phrases ",
        valueKey: "addPhrases",
        type: "multi-select",
        required: true,
        id: "add-phrases",
        disabled: false,
        placeholder: "Type a phrase & click enter",
        phrases: phrases,
        value: intentDetails.addPhrases,
        handleOnChange: handleIntentInputChange,
        handleInputKeyDown: handleKeyDown,
        errorMessage: errorMessage["addPhrases"],
      },
      {
        label: "Description",
        valueKey: "description",
        type: "textarea",
        required: true,
        id: "description",
        disabled: false,
        placeholder: "Enter message",
        value: intentDetails.description,
        handleOnChange: handleOnChange,
        errorMessage: errorMessage["description"],
      },
    ],
    [intentDetails.intentName, intentDetails.addPhrases, intentDetails.description, errorMessage, phrases]
  );

  return (
    <div className="intent-form-container">
      <Grid container spacing={1} className="intent-container">
        {IntentDetailsInputFields.map((item, index) => (
          <Grid item xs={10} key={index}>
            <Label title={item.label} />
            {item.valueKey === "addPhrases" && (
              <div className="addPhrasesContainer">
                <Input
                  type={item.type}
                  disabled={item.disabled}
                  required={item.required}
                  id={item.valueKey}
                  label={item.label}
                  handleOnChange={item.handleOnChange}
                  value={item.value}
                  placeholder={item.placeholder}
                  handleKeyDown={item.handleInputKeyDown}
                  errorMessage={item.errorMessage}
                />

                {item.phrases.map((phrase, phraseIndex) => (
                  <div key={phraseIndex} className="phraseContainer">
                    <span key={phraseIndex} className="phrase">
                      {phrase}
                      <CancelIcon
                        className="button"
                        onClick={() => removePhrase(phraseIndex)}
                        fontSize="small"
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </div>
                ))}
              </div>
            )}
            {item.valueKey !== "addPhrases" && (
              <Input
                type={item.type}
                disabled={item.disabled}
                required={item.required}
                id={item.valueKey}
                label={item.label}
                handleOnChange={item.handleOnChange}
                value={item.value}
                placeholder={item.placeholder}
                handleKeyDown={item.handleInputKeyDown}
                errorMessage={item.errorMessage}
              />
            )}
          </Grid>
        ))}

        <Grid item xs={10} className="save-button-class">
          <Button label={"Save"} handleClick={handleSave} />
        </Grid>
      </Grid>
    </div>
  );
}