import * as React from "react";
import "./companyDetails.css";
import Button from "../../utilities/button";
import CheckIcon from "@mui/icons-material/Check";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import './companyDetails.css';
export default function GuideMessage({ handleClose }) {
  const copyToClipboard = () => {
    const textToCopy = "Contact-Center-CrossAccountPermission"; // Text you want to copy
   
    navigator.clipboard.writeText(textToCopy)
      // .then(() => {
      //   alert('Text copied to clipboard');
      // })
      // .catch((error) => {
      //   console.error('Failed to copy: ', error);
      // });
  };
  return (
   
     <Grid item xs={12}className="">
     <p className="modal-paragraph">
        <span className="step-css" onClick={copyToClipboard}>Step1:</span> Launch the CloudFormation stack by clicking the link in the Step 2, before clicking the link, make sure you should be signed in as an Administrator or with CloudFormation and IAM full access to your login.
      </p>
      <p className="modal-paragraph">
        <span className="step-css">Step2:</span> Click on the adjacent hyperlink to launch the stack
        – <a href="https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create?stackName=Contact-Center-Builder&templateURL=https://contact-center-builder.s3.amazonaws.com/Contact-Center-Builder-CrossAccount-Production.json" target="_blank">Launch Stack</a>.
 
      </p>
      {/* <p className="modal-paragraph">
        <span className="step-css" onClick={copyToClipboard}>Step2:</span> You will be redirected to the
        CloudFormation page to launch the stack if you are already signed in
        with the administrator credentials. If not, then sign in with the same.
      </p> */}
      <p className="modal-paragraph">
        <span className="step-css">Step3:</span> On successfully signing in, the auto filled options
        will be displayed. Click on the <span className="step-css">‘NEXT’ </span>
         button on the next 3 consecutive screens for 'Create Stack', 'Specify
        Stack Details' and 'Configure Stack Options'.
      </p>
      <p className="modal-paragraph">
        <span className="step-css">Step4:</span> On the
        <span className="step-css" > 'Review & Create'</span> page check the box
        in front of 'I acknowledge that AWS CloudFormation might create IAM
        resources.'' and click on the <span className="step-css"> ‘SUBMIT’</span>{" "}
        button.
      </p>
      <p className="modal-paragraph">
        <span className="step-css">Step5:</span> The stack will be launched with
        the status ‘Create Complete’ . Now search for ‘IAM’ through the console
        search bar and navigate to the IAM Homepage.
      </p>
      <p className="modal-paragraph">
        <span className="step-css">Step6:</span> Click on{" "}
        <span className="step-css">'ROLES'</span> towards the left-hand side of
        the screen and search for the role<span className="copy-text" onClick={copyToClipboard}>
        'Contact-Center-Builder-CrossAccountPermission' </span>with a 12 alphanumeric
        code suffix.
      </p>
 
      <p className="modal-paragraph">
        <span className="step-css">Step7:</span> Click on this role. Copy the ARN
        using the copy symbol next to the ARN text and paste it in the box
        provided on the Contact Center Builder screen.
      </p>
     
        <p className="modal-paragraph">
        <span className="step-css"> Note:</span> If you have any queries while performing the above steps,
        please reach out to us at
        <br></br>
        <a href="mailto:support-ccb@connectgenus.com">
          support-ccb@connectgenus.com
        </a>
        </p>
       
    </Grid>

  );
}