import * as React from "react";
import { Grid, IconButton } from "@mui/material";
import MainTheme from "../mainTemplate";
import Input from "../../utilities/input";
import Title from "../../utilities/title";
import Label from "../../utilities/label";
import {
  adminDetailsContext,
  companyDetailsContext,
  notFoundStatusContext,
} from "../../context";
import {
  validateEmail,
isValidEmail,
  validateUserName,validatePhoneNumber
} from "../../preDefinedFunctions/inputFieldValidations";
 
import { AlternateEmail } from "@mui/icons-material";
import { axiosInstance } from "../../services/axiosInstance";
import { endpoints } from "../../services/endpoints";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "./admin.css";
import { isValidPassword } from "../../preDefinedFunctions/inputFieldValidations.js"
 
export default function Administrator() {
  let companyDetails = React.useContext(companyDetailsContext);
  const [errors, setErrors] = React.useState({
    alternateEmails: [],
    alternateNames: [],
  });
  const [loading, setLoading] = React.useState(false);
  const allow_chat_enabled = companyDetails.contactOptions.allowChat === true;
  let administratorDetails = React.useContext(adminDetailsContext);
  let notFoundStatus = React.useContext(notFoundStatusContext);
  const [passwordVisibility, setPasswordVisibility] = React.useState(false);
  const [adminDetails, setAdminDetails] = React.useState({
    firstName: administratorDetails.firstName || "",
    lastName: administratorDetails.lastName || "",
    email: administratorDetails.email || "",
    phoneNumber: administratorDetails.phoneNumber || "",
    userName: administratorDetails.userName || "",
    password: administratorDetails.password || "",
    confirmPassword: administratorDetails.confirmPassword || "",
    AlternateEmail: administratorDetails.AlternateEmail || "",
    AlternateName: administratorDetails.AlternateName || "",
    countryCode: administratorDetails.countryCode || "",
    allow_chat_enabled: allow_chat_enabled,
  });
  // const encryptPassword = (password) => {
  //   return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(password));
  // };
 
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    React.useState(false);
  const [countryCodes, setCountryCodes] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState({});
  const [validMessage, setValidMessage] = React.useState({});
  const [passwordRequirements, setPasswordRequirements] = React.useState({
    lowercase: false,
    uppercase: false,
    number: false,
    specialCharacter: false,
    length: false,
  });
 
  React.useEffect(() => {
    notFoundStatus.status = false;
    // getCountryCodes();
  }, []);
  // async function getCountryCodes() {
  //   try {
  //     const response = await axiosInstance.get(
  //       endpoints.claimPhoneNumber.getCountryList
  //     );
  //     if (response.status === 200) {
  //       const data = response.data;
  //       setCountryCodes(data);
  //     }
  //   } catch (error) {}
  // }
  // function removeDuplicates(arr) {
  //   let unique = [];
  //   arr.forEach((element) => {
  //     if (!unique.includes(element.callingCodes)) {
  //       unique.push(element.callingCodes);
  //     }
  //   });
  //   return unique;
  // }
 
  const AdminDetailsInputFields = React.useMemo(
    () => [
      {
        label: "First name",
        valueKey: "firstName",
        type: "text",
        required: true,
        id: "first-name",
        disabled: false,
        autocomplete: "nope",
        placeholder: "e.g Stephen",
        errorMessage: errorMessage["firstName"],
      },
      {
        label: "Last name",
        valueKey: "lastName",
        type: "text",
        required: true,
        id: "last-name",
        disabled: false,
        placeholder: "e.g Mathew",
        errorMessage: errorMessage["lastName"],
      },
      {
        label: "Phone number",
        valueKey: "phoneNumber",
        type: "phoneNumber",
        required: true,
        id: "phone-number",
        disabled: false,
        placeholder: "e.g 2345678901",
        value: administratorDetails.phoneNumber,
        countryCode: administratorDetails.countryCode,
        onKeyDown: `countryCodes.callingCodes`,
        errorMessage: errorMessage["phoneNumber"],
        // options: removeDuplicates(countryCodes),
       
        validMessage: validMessage["phoneNumber"],
      },
      {
        label: "User name",
        valueKey: "userName",
        type: "text",
        required: true,
        id: "user-id",
        disabled: false,
        placeholder: "e.g Mathew_01",
        errorMessage: errorMessage["userName"],
      },
      {
        label: "Email",
        valueKey: "email",
        type: "email-text",
        required: true,
        id: "email",
        disabled: false,
        placeholder: "e.g stephen@example.com",
        errorMessage: errorMessage["email"],
      },
      {},
      {
        label: "Password",
        valueKey: "password",
        type: "password",
        required: true,
        id: "password",
        disabled: false,
        visibility: passwordVisibility,
        placeholder: "e.g Mathew$01",
        errorMessage: errorMessage["password"],
      },
      {
        label: "Confirm password",
        valueKey: "confirmPassword",
        type: "password",
        required: true,
        id: "confirm-password",
        disabled: false,
        visibility: confirmPasswordVisibility,
        placeholder: "e.g Mathew$01",
        errorMessage: errorMessage["confirmPassword"],
      },
    ],
    [passwordVisibility, confirmPasswordVisibility, errorMessage, countryCodes]
  );
  const [showEmailField, setShowEmailField] = React.useState(false);
 
  const addAlternateEmail = () => {
    if (alternateInputs.length < 5) {
      const newInput = {};
      alternateInputFields.forEach((item) => {
        newInput[item.valueKey] = "";
      });
      setAlternateInputs([...alternateInputs, newInput]);
      setShowEmailField(true);
    }
  };
 
  const removeAlternateEmail = (indexToRemove) => {
    const newInputs = alternateInputs.filter((_, i) => i !== indexToRemove);
    setAlternateInputs(
      alternateInputs.filter((_, index) => index !== indexToRemove)
    );
    if (newInputs.length === 0) {
      setShowEmailField(false);
    }};
  const handleValidateAlternateEmail = (index, value) => {
    const newErrors = { ...errors };
    if (!validateEmail(value)) {
      newErrors.alternateEmails[index] = "Enter valid email address";
    } else {
      newErrors.alternateEmails[index] = "";
    }
    setErrors(newErrors);
 
    setAdminDetails((prevDetails) => ({
      ...prevDetails,
      AlternateEmail: alternateInputs.map((input) => input.email),
    }));
  };
 
  const handleInputChange = (index, itemKey, value) => {
    const newInputs = [...alternateInputs];
 
    newInputs[index][itemKey] = value;
    setAlternateInputs(newInputs);
 
    if (itemKey === "email") {
      handleValidateAlternateEmail(index, value);
    }
 
    setAdminDetails((prevDetails) => ({
      ...prevDetails,
      AlternateEmail: newInputs.map((input) => input.email), 
      AlternateName: newInputs.map((input) => input.Name), 
    }));
  };
 
  const [alternateInputs, setAlternateInputs] = React.useState([]);
 
  const toggleEmailField = () => {
    setShowEmailField(!showEmailField);
  };
 
  const [emailDetails, setEmailDetails] = React.useState(
    administratorDetails.emailDetails
  );
  const alternateInputFields = React.useMemo(
    () => [
      {
        label: "Name",
        valueKey: "Name",
        type: "text",
        required: true,
 
        id: "Name",
        disabled: false,
        placeholder: "e.g Mathew_01",
        errorMessage: errorMessage["Name"],
      },
      {
        label: "Email",
        valueKey: "email",
        type: "text",
        required: true,
        id: "email",
        disabled: false,
 
        placeholder: "e.g stephen@example.com",
        errorMessage: errorMessage["email"],
      },
    ],
    [emailDetails]
  );
  

  // const handlePasswordValidation = (id, value) => {
  //   const errors = { ...errorMessage };
  //   const valid = { ...validMessage };
  //   const requirements = { ...passwordRequirements };
 
  //   let lowercaseRegex = /[a-z]/g;
  //   let uppercaseRegex = /[A-Z]/g;
  //   let numbersRegex = /[0-9]/g;
  //   let specialCharRegex = /[^a-zA-Z0-9]/g;
 
  //   requirements.lowercase = lowercaseRegex.test(value);
  //   requirements.uppercase = uppercaseRegex.test(value);
  //   requirements.number = numbersRegex.test(value);
  //   requirements.specialCharacter = specialCharRegex.test(value);
  //   requirements.length = value.length >= 8;
 
  //   setPasswordRequirements(requirements);
  //   if (id === "password") {
  //     if (!requirements.lowercase) {
  //       errors[id] = "Password must contain at least one lowercase letter.";
  //     } else if (!requirements.uppercase) {
  //       errors[id] = "Password must contain at least one uppercase letter.";
  //     } else if (!requirements.number) {
  //       errors[id] = "Password must contain at least one number.";
  //     } else if (!requirements.specialCharacter) {
  //       errors[id] = "Password must contain at least one special character.";
  //     } else if (!requirements.length) {
  //       errors[id] = "Password must be at least 8 to 64 characters.";
  //     }else if (
  //       adminDetails.confirmPassword.length > 0 &&
  //       value !== adminDetails.confirmPassword
  //     ) {
  //       errors["confirmPassword"] = "Password do not match";
  //     } else {
  //       errors["confirmPassword"] = "";
  //     }
  //   }else if (id === "confirmPassword") {
  //     if (value !== adminDetails.password) {
  //       errors[id] = "Passwords do not match";
  //     } else {
  //       errors[id] = "";
  //     }
  //   }
  //   else {
  //     setLoading(true)
  //     errors[id] = "";
  //     valid[id] = "valid password";
  //   }
  //   setErrorMessage(errors);
  //   setValidMessage(valid);
  // };
 
  const handleOnCountryCode = (e) => {
    const { value } = e.target;
    setAdminDetails((prevDetails) => ({
      ...prevDetails,
      countryCode: value,
    }));
  };
 
  const handleValidateEmail = (id, value) => {
    if (!validateEmail(value)) {
      setErrorMessage({ [id]: "Enter valid email address" });
      setAdminDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    } else {
      setErrorMessage({});
      setAdminDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    }
  };
  const handleValidateUserName = (id, value) => {
    if (!validateUserName(value)) {
      setErrorMessage({
        [id]: "Enter valid user name, should follow email's format when using @ (at)",
      });
      setAdminDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    } else {
      setErrorMessage({});
      setAdminDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    }
  };

  const handleValidatePhoneNumber = (id, value) => {
    if (!adminDetails.countryCode) {
      setErrorMessage({ [id]: "Please select country code" });
    } else {
      setErrorMessage({});
    }
    if (!/^\+?[0-9]{1,}$/i.test(value)) {
      setLoading(false);
      setErrorMessage({
        [id]: "Please enter valid phone number - only numbers",
      });
       
      setAdminDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
      return;
    } else if (value.length < 10) {
      setLoading(false);
      setErrorMessage({
        [id]: "Please provide valid phone number with 10 digits",
      });
      setAdminDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
      return;
    } else {
      setLoading(false);
      setErrorMessage({});
      setAdminDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    }
  };
 
    const handleOnChange = (e) => {
      const { id, value } = e.target;
      setAdminDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value
    }));
      if (id === "password" || id === "confirmPassword") {
        setAdminDetails((prevDetails) => ({
          ...prevDetails,
          [id]: value
      }));
      }
      
      if (id === "email") {
        handleValidateEmail(id, value);
      }
      if (id === "phoneNumber") {
       if(!validatePhoneNumber(value)){
        setErrorMessage({
          [id]:"Please provide valid PhoneNumber. "
        });
        return;
       }
       setErrorMessage("")
      }
      if (id === "phoneNumber") {
        validatePhoneNumber(id, value);
      }
      if (id === "userName") {
        handleValidateUserName(id, value);
      }
      if (id === "password" || id === "confirmPassword") {
        isValidPassword(id, value); 
      }
      if (id === "password") {
        if (!isValidPassword(value)) {
            setErrorMessage({
                [id]: "Password must have 1 lowercase, 1 uppercase, 1 number, 1 special character, minimum 8 charecters."
            });
            return;
        }
        setErrorMessage("")
    }
    
    };
  
    const handleVisibilty = (e) => {
      const { id } = e.target;
      if (id === "confirmPassword") {
        setConfirmPasswordVisibility(!confirmPasswordVisibility);
      }
      if (id === "password") {
        setPasswordVisibility(!passwordVisibility);
      }
    };
  React.useEffect(() => {
    administratorDetails.firstName = adminDetails.firstName;
    administratorDetails.lastName = adminDetails.lastName;
    administratorDetails.email = adminDetails.email;
    administratorDetails.phoneNumber =  adminDetails.phoneNumber;
    administratorDetails.userName = adminDetails.userName;
    administratorDetails.password = adminDetails.password;
    administratorDetails.confirmPassword = adminDetails.confirmPassword;
    administratorDetails.AlternateEmail = adminDetails.AlternateEmail;
    administratorDetails.AlternateName = adminDetails.AlternateName;
  }, [adminDetails, administratorDetails]);

  return (
    <MainTheme>
      <Title title={"Setup an administrator"} />
      <div className="adminstarator-details-section">
        <Grid container spacing={2} className="container-admin">
          {AdminDetailsInputFields.map((item, index) => (
            <Grid item md={6} xs={12} key={index} className="input-div-class">
              <Label title={item.label} />
              <Input
                className="input-fields"
                type={item.type}
                disabled={item.disabled}
                required={item.required}
                id={item.valueKey}
                label={item.label}
                options={item.options}
                handleOnChange={handleOnChange}
                handleOnCountryCode={handleOnCountryCode}
                countryCode={item.countryCode}
                value={adminDetails[item.valueKey]}
                placeholder={item.placeholder}
                visibility={item.visibility}
                handleVisibilty={handleVisibilty}
                errorMessage={item.errorMessage}
              />
            </Grid>
          ))}
          <Grid item xs={12} className="input-div-class">
          {!showEmailField ? (
          <p className="link-hover">
            <a onClick={addAlternateEmail} style={{ cursor: 'pointer' }}>
              + Add Alternate Emails
              <IconButton></IconButton>
            </a>
          </p>
        ) : (
          <p className="disablealter-text">+ Add Alternate Emails</p>
        )}
          </Grid><Grid container spacing={2}>
      {showEmailField && (
        <Grid container spacing={2} className="container-admin">
          {alternateInputs.map((input, index) => (
            <Grid
              item
              md={12}
              xs={12}
              key={index}
              className="input-div-class"
            >
              <Grid
                container
                alignItems="center"
                spacing={2}
                className="container-section-details-form"
              >
                {alternateInputFields.map((item, i) => (
                  <Grid item key={i} xs={4.97} >
                    <Label title={item.label} />
                    <input
                      className="alternate-input-class"
                      type={item.type}
                      disabled={item.disabled}
                      required={item.required}
                      id={item.valueKey}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          item.valueKey,
                          e.target.value
                        )
                      }
                      value={input[item.valueKey]}
                      placeholder={item.placeholder}
                      visibility={item.visibility}
                      handleVisibilty={handleVisibilty}
                      errorMessage={item.errorMessage}
                    />
                    {item.valueKey === 'email' && errors.alternateEmails[index] && (
                      <span className="error-message">
                        {errors.alternateEmails[index]}
                      </span>
                    )}
                  </Grid>
                ))}
                     <Grid item xs={2} container className="grid-item" justifyContent="flex-end" style={{ marginLeft: '-69px', marginTop: '8px' }}>
                  {/* <IconButton onClick={() => removeAlternateEmail(index)}>
                    <CancelIcon
                      fontSize="small"
                      style={{ cursor: 'pointer', marginTop: '17px' }}
                    />
                  </IconButton> */}
                   <CancelIcon    className="cancel-style" onClick ={() =>removeAlternateEmail(index)}
                  
                      fontSize="small"
                      style={{ cursor: 'pointer', marginTop: '25px' }}
                    />
                  {/* {index === alternateInputs.length - 1 ? (
                    <IconButton onClick={addAlternateEmail}>
                      <AddCircleIcon className="add-button" />
                    </IconButton>
                  ) : (
                    <div style={{ width: 44 }}></div> // Placeholder for consistent spacing
                  )} */}
               { index === alternateInputs.length - 1 && alternateInputs.length < 5 ? (
  <AddCircleIcon className="add-button" fontSize="small" onClick={addAlternateEmail}/>
) : (
  <div className="placeholder"></div>

)}
 <div className="placeholder"></div>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
        </Grid>
      </div>
    </MainTheme>
  );
}