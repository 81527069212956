import React, { useContext, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../utilities/input";
import Label from "../../utilities/label";
import { Grid, Typography } from "@mui/material";
import {
  mannuallyDetailsContext,
  companyDetailsContext,
  addAgentsContext,homePageContext,
} from "../../context";
import { axiosInstance } from "../../services/axiosInstance";
import { endpoints } from "../../services/endpoints";
import Button from "../../utilities/button";
import {
  validateEmail,
  isValidEmail,
  
  validateUserName,
} from "../../preDefinedFunctions/inputFieldValidations";
import { ErrorServiceLogger } from "../../services/errorServiceLogger";
import SnackBar from "../../utilities/snackBar";
import CryptoJS from 'crypto-js';
import { isValidPassword, validatePhoneNumber } from "../../preDefinedFunctions/inputFieldValidations.js"


export default function DetailsForm({ handleClose,loading,setLoading }) {
  const companyDetails = useContext(companyDetailsContext);
  let addAgents = useContext(addAgentsContext);
  const [agentDetails, setAgentDetails] =React. useState({
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    userRole: "",
    routingProfile: "",
    countryCode: "+1",
  });
  const [passwordVisibility, setPasswordVisibility] = React.useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    React.useState(false);
  const homePageDetails = React.useContext(homePageContext);
  let mannuallyDetails = React.useContext(mannuallyDetailsContext);
  const [firstName, setFirstName] = React.useState(mannuallyDetails.firstName);
  const [lastName, setLastName] = React.useState(mannuallyDetails.lastName);
  const [email, setEmail] = React.useState(mannuallyDetails.email);
  const [userName, setUserName] = React.useState(mannuallyDetails.userName);
  const [password, setPassword] = React.useState(mannuallyDetails.password );
  const [confirmPassword, setConfirmPassword] = React.useState(
    mannuallyDetails.confirmPassword 
  );
  const [phoneNumber, setPhoneNumber] = React.useState(
    mannuallyDetails.phoneNumber
  );
  const [userRole, setUserRole] = React.useState(mannuallyDetails.userRole);
  const [routingProfile, setRoutingProfile] = React.useState(
    mannuallyDetails.routingProfile
  );
  const [countryCodes, setCountryCodes] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState({});
  const [validMessage, setValidMessage] = React.useState({});
  const [passwordRequirements, setPasswordRequirements] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    specialCharacter: false,
    length: false,
  });
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [pageErrorMessage, setPageErrorMessage] = useState("");
  // const encryptPassword = (password) => {
  //   return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(password));
  // };

  // React.useEffect(() => {
  //   getCountryCodes();
  // }, []);
  // async function getCountryCodes() {
  //   try {
  //     const response = await axiosInstance.get(
  //       endpoints.claimPhoneNumber.getCountryList
  //     );
  //     if (response.status === 200) {
  //       const data = response.data;
  //       setCountryCodes(data);
  //     }
  //   } catch (error) {}
  // }

  // function removeDuplicates(arr) {
  //   let unique = [];
  //   arr.forEach((element) => {
  //     if (!unique.includes(element.callingCodes)) {
  //       unique.push(element.callingCodes);
  //     }
  //   });
  //   return unique;
  // }

  const agentDetailsInputFields = [
    {
      label: "First name",
      valueKey: "firstName",
      value: firstName,
      type: "text",
      required: true,
      id: "first-name",
      disabled: false,
      placeholder: "e.g Stephen",
      errorMessage: errorMessage["firstName"],
      validMessage: validMessage["firstName"],
    },
    {
      label: "Last name",
      valueKey: "lastName",
      value: lastName,
      type: "text",
      required: true,
      id: "last-name",
      disabled: false,
      placeholder: "e.g Mathew",
      errorMessage: errorMessage["lastName"],
      validMessage: validMessage["lastName"],
    },
    {
      label: "Email",
      valueKey: "email",
      type: "text",
      value: email,
      required: true,
      id: "email",
      disabled: false,
      placeholder: "e.g stephen@example.com",
      errorMessage: errorMessage["email"],
      validMessage: validMessage["email"],
    },
    {
      label: "Phone number",
      valueKey: "phoneNumber",
      type: "phoneNumber",
      required: true,
      id: "phone-number",
      disabled: false,
      placeholder: "e.g 2345678901",
      value: phoneNumber,
      countryCode: agentDetails.countryCode,
      onKeyDown: `countryCodes.callingCodes`,
      errorMessage: errorMessage["phoneNumber"],
      // options: removeDuplicates(countryCodes),
      validMessage: validMessage["phoneNumber"],
    },
    {
      label: "User name",
      valueKey: "userName",
      value: userName,
      type: "text",
      required: true,
      id: "userName",
      disabled: false,
      placeholder: "e.g Mathew_01",
      errorMessage: errorMessage["userName"],
      validMessage: validMessage["userName"],
    },
    {
      label: "User role",
      valueKey: "userRole",
      type: "select",
      value: userRole,
      required: true,
      id: "userRole",
      disabled: false,
      placeholder: "Select user role",
      options: ["Agent", "Admin", "Call Center Manager", "Quality Analyst"],
      errorMessage: errorMessage["userRole"],
      validMessage: validMessage["userRole"],
    },
    {
      label: "Password",
      valueKey: "password",
      type: "password",
      required: true,
      id: "password",
      value:password,
      disabled: false,
      visibility: passwordVisibility,
      placeholder: "e.g Mathew$01",
      errorMessage: errorMessage["password"],
    },
    {
      label: "Confirm password",
      valueKey: "confirmPassword",
      type: "password",
      required: true,
      value:confirmPassword,
      id: "confirm-password",
      disabled: false,
      visibility: confirmPasswordVisibility,
      placeholder: "e.g Mathew$01",
      errorMessage: errorMessage["confirmPassword"],
    },
    {
      label: "Routing profile",
      valueKey: "routingProfile",
      type: "select",
      value: routingProfile,
      required: true,
      id: "routingrofile",
      disabled: false,
      placeholder: "Select routing profile",
      options: ["Basic Routing Profile"],
      errorMessage: errorMessage["routingProfile"],
      validMessage: validMessage["routingProfile"],
    },
    [
      errorMessage,
      validMessage,
      routingProfile,
      userRole,
      agentDetails,
      phoneNumber,
      confirmPasswordVisibility,
      confirmPassword,
      password,
      passwordVisibility,
      userName,
      lastName,
      firstName,
      email,
    ],
  ];
  const handleVisibilty = (e) => {
    const { id } = e.target;
    if (id === "confirmPassword") {
      setConfirmPasswordVisibility(!confirmPasswordVisibility);
    }
    if (id === "password") {
      setPasswordVisibility(!passwordVisibility);
    }
  };

  const handleOnCountryCode = (e) => {
    const { value } = e.target;
    setAgentDetails((prevDetails) => ({
      ...prevDetails,
      countryCode: value,
    }));
  };
  const handleValidatePhoneNumber = (id, value) => {
    // if (!othersDetails.countryCode) {
    //   setErrorMessage({ [id]: "Please select country code" });
    // } else {
    //   setErrorMessage({});
    // }
    if (!validatePhoneNumber(value)) {
      setErrorMessage({
        [id]: "Please enter valid phone number - only numbers",
      });
      setAgentDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
      return;
    } else if (value.length < 10) {
      setErrorMessage({
        [id]: "Please Enter valid phone number with 10 digits",
      });
      setAgentDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
      return;
    } else {
      setErrorMessage({});
      setAgentDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    }
  };

  const handleOnChange = (e) => {
    const { id, value, name } = e.target;
  
    // Update the agent details state
    setAgentDetails((prevDetails) => ({
      ...prevDetails,
      [id]: value
    }));
    if (id === "password" || id === "confirmPassword") {
      setAgentDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value
    }));
    }
    // Perform validation based on the input field ID
    if (id === "phoneNumber") {
     // validatePhoneNumber(id, value);
     handleValidatePhoneNumber(id,value);
    }
    
  if(id==="password"){
    setPassword(value);
  }
    if (id === "email") {
      handleValidateEmail(id, value);
      isValidEmail(id, value);
    }
    if (id === "password" || id === "confirmPassword") {
      isValidPassword(id, value); 
    }
    if (id === "password" || id === "confirmPassword") {
      isValidPassword(id, value);
      if (id === "password" && !isValidPassword(value)) {
        setErrorMessage((prevError) => ({
          ...prevError,
          [id]: "Password must have 1 lowercase, 1 uppercase, 1 number, 1 special character, minimum 8 characters."
        }));
        return;
      } else {
        setErrorMessage((prevError) => ({
          ...prevError,
          [id]: ""
        }));
      }
    }
  
    switch (id ? id : name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "userName":
        setUserName(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      case "phoneNumber":
        setPhoneNumber(value);
        break;
      case "userRole":
        setUserRole(value);
        break;
      case "routingProfile":
        setRoutingProfile(value);
        break;
      default:
        break;
    }
  };
  const handleFormFieldsValidation = () => {
    const errors = {};
  
    if (!firstName) {
      errors.firstName = "First Name is required";
    }
    if (!lastName) {
      errors.lastName = "Last Name is required";
    }
    if (!email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(email)) {
      errors.email = "Invalid email format";
    }
    if (!userName) {
      errors.userName = "User Name is required";
    }
    if (!password) {
      errors.password = "Password is required";
    } else if (!isValidPassword(password)) {
      errors.password = "Please provide a valid password";
    }
    if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    } else if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    if (!phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    } else if (!validatePhoneNumber(phoneNumber)) {
      errors.phoneNumber = "Please enter a valid phone number";
    }
    if (!userRole) {
      errors.userRole = "User Role is required";
    }
    if (!routingProfile) {
      errors.routingProfile = "Routing Profile is required";
    }
  
    setErrorMessage(errors);
  
    // Return true if no errors, false otherwise
    return Object.keys(errors).length === 0;
  };
  
  
  const handleFormSubmit = () => {
    // Validate form fields
    const isValid = handleFormFieldsValidation();
  
    // Prepare the mobile number in the desired format
    const es6MobileNumber = "+1" + phoneNumber;
  
    // Check if the form is valid
    if (!isValid) {
      // If not valid, set loading to false and display an error message
      setLoading(false);
      setPageErrorMessage("Please correct all the highlighted sections");
    } else {
      // If valid, set loading to true and proceed with form submission
      setLoading(true);
  setPageErrorMessage("")
      // Prepare the request body
      const body = {
        userName,
        password,
        confirmPassword,
        firstName,
        lastName,
        email,
        phoneNumber: es6MobileNumber,
        userRole: userRole.split(" ").join(""),
        routingProfile,
        accessUrl: companyDetails.companyAccessUrl,
        uniqueId: homePageDetails.uId,
        regionName: companyDetails.awsAccountDetails.region,
        companyName: companyDetails.companyName,
        roleArn: companyDetails.awsAccountDetails.roleArn,
      };
  
      // Prepare the request headers
      const headers = {
        "Authorization": companyDetails.awsToken + companyDetails.accountNumber,
      };
  
      // Make the API request using axiosInstance
      axiosInstance
        .post(endpoints.agentDetails.detailsSubmit, body, {
          headers: headers,
        })
        .then((response) => {
          // Handle the response
          if (response.data.statusCode === 200) {
            setLoading(false);
            setPageErrorMessage("");
            setSnackBarOpen(true);
            addAgents.status = true;
            setTimeout(() => {
              handleClose();
            }, 2000);
          } else {
            // Handle the error response
            setLoading(false);
            const errorMessage = response.data;
            setPageErrorMessage("This username is already taken. Please try choosing a different one.");
            ErrorServiceLogger(
              errorMessage,
              companyDetails.companyAccessUrl,
              body,
              endpoints.agentDetails.detailsSubmit
            );
          }
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          setLoading(false);
          setPageErrorMessage("An error occurred while submitting the form. Please try again.");
          ErrorServiceLogger(
            error,
            companyDetails.companyAccessUrl,
            body,
            endpoints.agentDetails.detailsSubmit
          );
        });
    }
  };
  
  
  const handleValidateEmail = (id, value) => {
    if (!validateEmail(value)) {
      setErrorMessage({ [id]: "Enter valid email address" });
      setAgentDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    } else {
      setErrorMessage({});
      setAgentDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    }
  };

  const handleValidateUserName = (id, value) => {
    if (!validateUserName(value)) {
      setErrorMessage({
        [id]: "Enter valid user name, should follow email's format when using @ (at)",
      });
      setAgentDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    } else {
      setErrorMessage({});
      setAgentDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    }
  };

  const isValidPhoneNumber = (id, value) => {
    if (!agentDetails.countryCode) {
      setErrorMessage({ [id]: "Please select country code" });
    } else {
      setErrorMessage({ [id]: "" });
    }
    if (!/^\+?[0-9]{1,}$/i.test(value)) {
      setErrorMessage({
        [id]: "Please enter valid phone number - only numbers",
      });
      setAgentDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
      return;
    } else if (value.length < 10) {
      setErrorMessage({
        [id]: "Please Enter valid phone number with 10 digits",
      });
      setAgentDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
      return;
    } else {
      setErrorMessage({ [id]: "" });
      setAgentDetails((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
    }
  };
  const handlePasswordValidation = (id, value) => {
    const errors = { ...errorMessage };
    const valid = { ...validMessage };
    const requirements = { ...passwordRequirements };

    let lowercaseRegex = /[a-z]/g;
    let uppercaseRegex = /[A-Z]/g;
    let numbersRegex = /[0-9]/g;
    let specialCharRegex = /[^a-zA-Z0-9]/g;

    requirements.lowercase = lowercaseRegex.test(value);
    requirements.uppercase = uppercaseRegex.test(value);
    requirements.number = numbersRegex.test(value);
    requirements.specialCharacter = specialCharRegex.test(value);
    requirements.length = value.length >= 8;

    setPasswordRequirements(requirements);
    if (id === "password") {
      if (!requirements.lowercase) {
        errors[id] = "Password must contain at least one lowercase letter.";
      } else if (!requirements.uppercase) {
        errors[id] = "Password must contain at least one uppercase letter.";
      } else if (!requirements.number) {
        errors[id] = "Password must contain at least one number.";
      } else if (!requirements.specialCharacter) {
        errors[id] = "Password must contain at least one special character.";
      } else if (!requirements.length) {
        errors[id] = "Password must be at least 8 to 64 characters.";
      } else {
        errors[id] = "";
        valid[id] = "valid password";
      }
      if (confirmPassword.length > 0 && value !== confirmPassword) {
        errors["confirmPassword"] = "Password do not match";
      } else {
        errors["confirmPassword"] = "";
      }
    }
    if (id === "confirmPassword") {
      if (value !== password) {
        errors[id] = "Passwords do not match";
      } else {
        errors[id] = "";
      }
    }
    setErrorMessage(errors);
    setValidMessage(valid);
  };

  return (
    <div>
      <Grid container spacing={1} className="container-section-details-form">
        {agentDetailsInputFields.map((item, index) => (
          <Grid
            item
            md={6}
            xs={10}
            spacing={1}
            key={index}
            className="input-div-class"
          >
            <Label title={item.label} />
            <Input
                className="input-fields"
                type={item.type}
                disabled={item.disabled}
                required={item.required}
                id={item.valueKey}
                label={item.label}
                options={item.options}
                handleOnChange={handleOnChange}
                handleOnCountryCode={handleOnCountryCode}
                countryCode={item.countryCode}
                value={item.value}
                placeholder={item.placeholder}
                visibility={item.visibility}
                handleVisibilty={handleVisibilty}
                errorMessage={item.errorMessage}
              />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        {pageErrorMessage && (
          <Typography className="error-text">{pageErrorMessage}</Typography>
        )}
      </Grid>
      <div className="submit-btn-class">
        {loading ? (
          <CircularProgress sx={{ color: "#1a567a" }} />
        ) : (
          <Button label="Submit" handleClick={handleFormSubmit}></Button>
        )}
      </div>
      <SnackBar
        openSnackBar={snackBarOpen}
        close={setSnackBarOpen}
        label={`Agent added successfully`}
      />
    </div>
  );
}