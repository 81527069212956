//page-1
export const endpoints = {
  getIndustryList: `IndustryUsecases`,//Changed
  companyDetails: {
    checkAccessURLInstance: "CheckAvailability",//Changed
    detailsSubmit: "CreateInstance",//Changed
    instanceStatusCheck: "InstanceStatus",//Changed
    tenantType:"RoleArnFetch",//Changed
    updateTenantTable:"UpdateTenancy",//Changed
    launchStack:"STSAttachPolicy",
    yourAccount:"KeyGenerator"//adde
  },
  othersDetails: {
    detailsSubmit: "OtherForm",//Changed
  },

  //page-2
  claimPhoneNumber: {
    getCountryList: "CountriesList",//Changed
    claim: "SearchAvailablePhoneNumbers",//Changed
    claimNumber: "ClaimPhoneNumber",//Changed
    bucketCreationSKPIAnalytics:"BucketCreation",//Changed
    instanceStorageConfig:'InstanceStorageConfig'//Updated
  },

  //page-3
  adminDetails: {
    detailsSubmit: "AddAdministrator",//Changed
    allowChatSubmit: "CreateBot",//Changed
    allowChatEmail: "AllowChatbotSes",//Changed
    agentActivity: "AgentActivity",//Changed
    ctr : "CtrSkpi",//Changed
    realTimeMetrics: "RealtimeMetricsSkpi",//Changed
  },

  //page-4
  selectQueues: {
    getCountryTimeZoneList: `TimeZones`,//Changed
    customPromptHandling: "CheckCustomPrompt",//Changed
    createContactFlow: "CreateContactFlow",//needToUpdate
    createHOPQueue: "CreatingHoursOfOperationandQueues",//needToUpdate
    addIntents: "AddIntents",//Changed
    voiceMailHandling: "VoicemailSES",//Changed
    buildBot: "BuildBot",//Changed
    contactFlowLogsSKPI:"FlowLogsTransferSkpi",//Added
    LexlogosSKPI:"LexBotLogsSkpi",//Added
    callRecording:"BucketRetention"//Changed
  },

  //page-5
  agentDetails: {
    detailsSubmit: "AddAgentsManually",//Changed
    csvTemplateDownload : "rapidconnectadduserscsvprod/User_management.csv",//NeedToChangeBucketName
    csvTemplateUpload: "UploadAgentDetailsCSVToS3Buckets",//Changed
    bulkUsersCreation: "TransferingCsv",//Changed
  },

  //page-6
  salesForceDetails: {
    detailsSubmit: "SalesforceIntegration",//Changed
  },
 
  //Last-Page
  successPage: {
    successMail: "SuccessMail",//Changed
    detachPolicy:"STSDetachPolicy"
  },

  //error-logs
  errorLoggers: "ErrorLogs",//Changed
};
