import dayjs from "dayjs";
import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Button from "../../utilities/button";
import "./selectQueues.css";

export default function CustomHours({ handleClose, setCustomHours, hoursConfigList }) {
  const [customHoursDays, setCustomHoursDays] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState("");

  React.useEffect(() => {
    if (hoursConfigList.length > 0) {
      const defaultCustomHoursDays = hoursConfigList.reduce((acc, item) => {
        const id = item.Day.toLowerCase();
        const day = item.Day.toUpperCase();
        const startTime = { Hours: item.StartTime.Hours, Minutes: item.StartTime.Minutes };
        const endTime = { Hours: item.EndTime.Hours, Minutes: item.EndTime.Minutes };
        return { ...acc, [id]: { Day: day, StartTime: startTime, EndTime: endTime } };
      }, {});
      setCustomHoursDays(defaultCustomHoursDays);
    }
  }, [hoursConfigList]);

  const dateFormatterInHHmm = (event) => {
    const date = new Date(event["$d"]);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return [hours, minutes];
  };

  const isEndTimeBeforeStartTime = (startTime, endTime) => {
    if (!startTime || !endTime) return false;
    const start = new Date();
    start.setHours(startTime.Hours, startTime.Minutes, 0, 0);
    const end = new Date();
    end.setHours(endTime.Hours, endTime.Minutes, 0, 0);
    return end <= start; // Updated to include `<=` to ensure end time is strictly after start time
  };

  const handleFromTimeChange = (event, id, day) => {
    const time = dateFormatterInHHmm(event);
    const updatedStartTime = {
      Day: day.toUpperCase(),
      StartTime: {
        Hours: parseInt(time[0], 10),
        Minutes: parseInt(time[1], 10),
      },
    };

    setCustomHoursDays((prevDetails) => {
      const updatedDays = { ...prevDetails, [id]: { ...prevDetails[id], ...updatedStartTime } };

      if (updatedDays[id]?.EndTime && isEndTimeBeforeStartTime(updatedStartTime.StartTime, updatedDays[id].EndTime)) {
        setErrorMessage(`End time cannot be before or the same as the start time for ${day}.`);
      } else {
        setErrorMessage("");
      }

      return updatedDays;
    });
  };

  const handleToTimeChange = (event, id, day) => {
    const time = dateFormatterInHHmm(event);
    const updatedEndTime = {
      Day: day.toUpperCase(),
      EndTime: {
        Hours: parseInt(time[0], 10),
        Minutes: parseInt(time[1], 10),
      },
    };

    setCustomHoursDays((prevDetails) => {
      const updatedDays = { ...prevDetails, [id]: { ...prevDetails[id], ...updatedEndTime } };

      if (updatedDays[id]?.StartTime && isEndTimeBeforeStartTime(updatedDays[id].StartTime, updatedEndTime.EndTime)) {
        setErrorMessage(`End time cannot be before or the same as the start time for ${day}.`);
      } else {
        setErrorMessage("");
      }

      return updatedDays;
    });
  };

  const parseTime = (timeObj) => {
    const { Hours, Minutes } = timeObj;
    const date = new Date();
    date.setHours(Hours);
    date.setMinutes(Minutes);
    return date;
  };

  const defaultCustomHoursFields = React.useMemo(
    () => [
      "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"
    ].map(day => ({
      id: day,
      day: day.charAt(0).toUpperCase() + day.slice(1),
      startTime: "",
      endTime: "",
      onFromTimeChange: handleFromTimeChange,
      onToTimeChange: handleToTimeChange,
    })),
    [customHoursDays]
  );

  const customHoursFields = React.useMemo(
    () => hoursConfigList.length === 0
      ? defaultCustomHoursFields
      : hoursConfigList.map((item) => ({
          id: item.Day.toLowerCase(),
          day: item.Day.charAt(0).toUpperCase() + item.Day.slice(1).toLowerCase(),
          startTime: parseTime(item.StartTime),
          endTime: parseTime(item.EndTime),
          onFromTimeChange: handleFromTimeChange,
          onToTimeChange: handleToTimeChange,
        })),
    [hoursConfigList]
  );

  const handleSave = () => {
    const missingEndDays = customHoursFields
      .filter(({ id }) => customHoursDays[id]?.StartTime && !customHoursDays[id]?.EndTime)
      .map(({ day }) => day);
    
    if (missingEndDays.length > 0) {
      setErrorMessage(`Please select end time for ${missingEndDays.join(", ")}.`);
      return;
    }

    const invalidTimes = customHoursFields
      .filter(({ id }) => {
        const startTime = customHoursDays[id]?.StartTime;
        const endTime = customHoursDays[id]?.EndTime;
        return isEndTimeBeforeStartTime(startTime, endTime);
      })
      .map(({ day }) => day);

    if (invalidTimes.length > 0) {
      setErrorMessage(`End time cannot be before or the same as the start time for ${invalidTimes.join(", ")}.`);
      return;
    }

    const isAnyStartTimePresent = Object.values(customHoursDays).some(day => day.StartTime);
    if (!isAnyStartTimePresent) {
      setErrorMessage("Before proceeding, please select start time and end time.");
      return;
    }

    setErrorMessage("");
    setCustomHours(Object.values(customHoursDays));
    handleClose();
  };

  return (
    <Grid container spacing={2} className="custom-hour-container">
      <div className="custom-hour-form-container">
        {customHoursFields.map((item, index) => (
          <Grid
            item
            xs={12}
            key={index}
            container
            spacing={1}
            className="custom-hour-days-container"
          >
            <Grid item xs={2.5}>
              <Typography>{item.day}</Typography>
            </Grid>
            <Grid item xs={4.5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    className="time-field-container"
                    label="Start time"
                    value={item.startTime ? dayjs(item.startTime) : null}
                    onChange={(event) =>
                      item.onFromTimeChange(event, item.id, item.day)
                    }
                    views={["hours", "minutes"]}
                    format="HH:mm"
                    ampm={false}
                    timeSteps={{ minutes: 1 }}
                    slotProps={{
                      textField: { size: "small", placeholder: "HH:MM" },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4.5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    className="time-field-container"
                    label="End time"
                    disabled={!customHoursDays[item.id]?.StartTime} 
                    value={item.endTime ? dayjs(item.endTime) : null}
                    onChange={(event) =>
                      item.onToTimeChange(event, item.id, item.day)
                    }
                    views={["hours", "minutes"]}
                    format="HH:mm"
                    ampm={false}
                    timeSteps={{ minutes: 1 }}
                    slotProps={{
                      textField: { size: "small", placeholder: "HH:MM" },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        ))}
      </div>
      <div className="custom-hour-button-container">
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <Button label="Save" handleClick={handleSave} />
      </div>
    </Grid>
  );
}
