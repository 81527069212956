export const passwordValidation = (password) => {
  var regularExpression =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  return regularExpression.test(password);
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z ]{2,}))$/
    );
};
export const isValidPassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>ยง~])(?=.{8,64})/;
  return regex.test(password);
};
export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
export const specialCharacterValidation = (value) => {
  return /^[0-9a-zA-Z ]+$/.test(value);
};


export const accessUrlValidation = (value) => {
  return /^[0-9a-zA-Z\-\b ]+$/.test(value);
};

export const validateUserName = (userName) => {
  if (!userName) {
    return false;
  }

  if (userName.includes("@")) {
    const emailRegex = /^[a-zA-Z0-9_\-+.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(userName);
  } else {
    const usernameRegex = /^[a-zA-Z0-9_\-+\.]+$/;
    return usernameRegex.test(userName);
  }
};

export const validateAwsAccountNumber = (accountNumber) => {
  if (!accountNumber) {
    return false;
  }
  return /^[0-9]+$/.test(accountNumber) && accountNumber.length == 12;
};

export const validateAllowChatDomainURL = (domianURL) => {
  return /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,63}\.)+(com|in|info|net|org|co|digital|biz|online)$/.test(domianURL);

}
export const validatePhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return false;
  }
  
  // Validate phone number (exactly 10 digits)
  const phoneRegex = /^\d{10}$/;
  return phoneRegex.test(phoneNumber);
};

export const validateRoleArn = (roleArn) => {
  const arnRegex = /^arn:aws:iam::\d{12}:role\/[a-zA-Z_0-9+=,.@\-_/]+$/;
  const invalidFormatMessage = "Invalid Role ARN format";

  if (!roleArn || !arnRegex.test(roleArn)) {
    return { isValid: false, message: invalidFormatMessage };
  } else {
    const parts = roleArn.split(':');
    if (
      parts.length < 6 ||
      parts[0] !== "arn" ||
      parts[1] !== "aws" ||
      parts[2] !== "iam" ||
      parts[3] !== "" ||
      !/^\d{12}$/.test(parts[4]) ||
      !parts[5].startsWith("role/")
    ) {
      return { isValid: false, message: invalidFormatMessage };
    } else {
      return { isValid: true, message: "" };
    }
  }
};
